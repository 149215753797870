import React, { useEffect, useMemo, useState } from 'react';
import { ButtonDropdown, ButtonDropdownField, Checkbox, DropdownList, FlexColumn, FlexRow, Heading, IconTooltip, Modal, SubHeading, Table, Text, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer } from 'react-relay';
import { exportInvoiceModalFragment } from './ExportInvoiceModal.query';
import Util from '../../../forecast-app/shared/util/util';
import { getNotNullishNodes } from '../../../forecast-app/shared/util/NotNullPredicate';
import { fetchXeroAccounts, onExportXeroInvoice } from './ExportInvoiceModal.xero';
import { hideLoader, showLoader } from '../../global_loader';
import { INVOICE_INTEGRATIONS } from '../../../forecast-app/admin-tab/finance-page/ts/InvoiceIntegrationsUtils';
import { exportInvoiceToQuickbooksOnline, fetchQuickbooksData, getInitialQuickbookSettings, } from './ExportInvoiceModal.quickbooks';
import { exportInvoiceToEconomics, fetchEconomicProducts } from './ExportInvoiceModal.economic';
const ExportInvoiceModal = ({ closeModal, viewer }) => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const { formatMessage } = intl;
    const noSpace = true;
    const { invoice, billTo } = viewer;
    const { client, currency } = invoice || {};
    const currencySymbol = useMemo(() => Util.GetCurrencySymbol(currency), [invoice === null || invoice === void 0 ? void 0 : invoice.currency]);
    const mapInvoiceEntryToRow = (invoiceEntry, xeroAccount, quickbookProductService, economicProduct) => {
        var _a, _b, _c;
        const taxRate = (xeroAccount === null || xeroAccount === void 0 ? void 0 : xeroAccount.taxRate) || 0;
        const row = Object.assign(Object.assign({}, invoiceEntry), { projectColor: ((_a = invoiceEntry.project) === null || _a === void 0 ? void 0 : _a.projectColor) || '', projectId: `P${(_b = invoiceEntry.project) === null || _b === void 0 ? void 0 : _b.companyProjectId}`, customProjectId: (_c = invoiceEntry.project) === null || _c === void 0 ? void 0 : _c.customProjectId, entryDescription: invoiceEntry.description, discountPercentage: (invoiceEntry.discount || 0) / 100, tax: taxRate, taxPercentage: taxRate / 100, totalPrice: Util.getInvoiceEntryAmount(invoiceEntry.unitPrice, invoiceEntry.quantity, 1, invoiceEntry.discount, taxRate) });
        if (xeroAccount) {
            row.label = xeroAccount.label;
            row.xeroAccount = xeroAccount.value;
            row.accountCode = xeroAccount.code;
        }
        if (quickbookProductService) {
            row.label = quickbookProductService.label;
            row.entryDescription = quickbookProductService.description;
            row.quickbooksItem = quickbookProductService.value;
            row.quickbooksTaxable = quickbookProductService.taxable;
            row.quickbooksUnitPrice = quickbookProductService === null || quickbookProductService === void 0 ? void 0 : quickbookProductService.unitPrice;
        }
        if (economicProduct) {
            row.label = economicProduct.label;
            row.economicProduct = economicProduct.value;
        }
        return row;
    };
    const [loading, setLoading] = useState(true);
    const [isReadyForExport, setIsReadyForExport] = useState(false);
    useEffect(() => {
        if (loading) {
            showLoader();
        }
        else {
            hideLoader();
        }
    }, [loading]);
    // INTEGRATION
    const isXeroInvoice = useMemo(() => { var _a; return ((_a = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _a === void 0 ? void 0 : _a.integration) === INVOICE_INTEGRATIONS.XERO; }, [(_a = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _a === void 0 ? void 0 : _a.integration]);
    const isQuickbooksInvoice = useMemo(() => { var _a; return ((_a = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _a === void 0 ? void 0 : _a.integration) === INVOICE_INTEGRATIONS.QUICKBOOKS; }, [(_b = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _b === void 0 ? void 0 : _b.integration]);
    const isEconomicInvoice = useMemo(() => { var _a; return ((_a = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _a === void 0 ? void 0 : _a.integration) === INVOICE_INTEGRATIONS.ECONOMIC; }, [(_c = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _c === void 0 ? void 0 : _c.integration]);
    // INVOICE
    const initialInvoiceEntries = getNotNullishNodes((_d = invoice === null || invoice === void 0 ? void 0 : invoice.entries) === null || _d === void 0 ? void 0 : _d.edges);
    const [invoiceRows, setInvoiceRows] = useState(() => initialInvoiceEntries.map(entry => mapInvoiceEntryToRow(entry)) || []);
    const invoiceTotals = useMemo(() => Util.getInvoiceTotals(invoiceRows), [invoiceRows]);
    // XERO
    const [projectInDescription, setProjectInDescription] = useState(false);
    const [selectedXeroAccount, setSelectedXeroAccount] = useState(null);
    const [xeroAccounts, setXeroAccounts] = useState([]);
    // QUICKBOOKS
    const [quickbooksTaxCodes, setQuickbooksTaxCodes] = useState([]);
    const [selectedQuickbooksCode, setSelectedQuickbooksCode] = useState(null);
    const [quickbooksProductsServices, setQuickbooksProductsServices] = useState([]);
    const [selectedQuickbooksProductService, setSelectedQuickbooksProductService] = useState(null);
    const [quickbookSettingsCount, setQuickbookSettingsCount] = useState(0);
    const [quickbookSettings, setQuickbookSettings] = useState(getInitialQuickbookSettings(intl));
    // E-CONOMIC
    const [economicProducts, setEconomicProducts] = useState([]);
    const [selectedEconomicProduct, setSelectedEconomicProduct] = useState(null);
    const dropdownColumnLabel = useMemo(() => {
        if (isXeroInvoice) {
            return formatMessage({ id: 'integrations.xero.xero_account' });
        }
        if (isQuickbooksInvoice) {
            return formatMessage({ id: 'integrations.quickbooks.item_or_service' });
        }
        if (isEconomicInvoice) {
            return formatMessage({ id: 'integrations.economic.product' });
        }
        return 'default';
    }, [isXeroInvoice, isQuickbooksInvoice, isEconomicInvoice]);
    useEffect(() => {
        setQuickbookSettingsCount(quickbookSettings.filter(item => item.checked).length);
    }, [quickbookSettings]);
    useEffect(() => {
        setInvoiceRows(initialInvoiceEntries.map(entry => mapInvoiceEntryToRow(entry, selectedXeroAccount, selectedQuickbooksProductService, selectedEconomicProduct)) || []);
    }, [selectedXeroAccount, selectedQuickbooksProductService, selectedEconomicProduct]);
    useEffect(() => {
        var _a;
        if (billTo) {
            setLoading(true);
            if (isXeroInvoice) {
                fetchXeroAccounts(billTo, (accounts) => {
                    setXeroAccounts(accounts);
                    setLoading(false);
                });
            }
            else if (isQuickbooksInvoice) {
                fetchQuickbooksData((taxCodes) => setQuickbooksTaxCodes(taxCodes), (taxCode) => setSelectedQuickbooksCode(taxCode), (productsServices) => setQuickbooksProductsServices(productsServices), () => setLoading(false), ((_a = billTo.billFrom) === null || _a === void 0 ? void 0 : _a.externalId) || '');
            }
            else if (isEconomicInvoice) {
                fetchEconomicProducts(products => {
                    setEconomicProducts(products);
                    setLoading(false);
                });
            }
        }
    }, [billTo]);
    const getMappedInvoiceRows = (row, xeroAccount, productService, economicProduct) => {
        return (invoiceRows.map(entry => {
            if (entry.id === row.id) {
                return mapInvoiceEntryToRow(entry, xeroAccount, productService, economicProduct);
            }
            return entry;
        }) || []);
    };
    const selectExpenseXeroAccount = (row, xeroAccount) => {
        setInvoiceRows(getMappedInvoiceRows(row, xeroAccount, null, null));
    };
    const selectQuickBooksProductService = (row, productService) => {
        setInvoiceRows(getMappedInvoiceRows(row, null, productService, null));
    };
    const selectEconomicProduct = (row, product) => {
        setInvoiceRows(getMappedInvoiceRows(row, null, null, product));
    };
    useEffect(() => {
        setIsReadyForExport(invoiceRows.every(row => {
            if (isXeroInvoice) {
                return !!row.xeroAccount;
            }
            if (isQuickbooksInvoice) {
                return !!row.quickbooksItem;
            }
            if (isEconomicInvoice) {
                return !!row.economicProduct;
            }
            return false;
        }));
    }, [invoiceRows]);
    const onSave = () => {
        if (invoice && billTo) {
            setLoading(true);
            const onSuccess = () => {
                setLoading(false);
                closeModal();
            };
            const onError = (error, onFailure) => {
                // Workaround. Old error modal is shown behind new design-system modals - so we have to close it first.
                onSuccess();
                onFailure(error);
            };
            if (isXeroInvoice) {
                onExportXeroInvoice({
                    intl,
                    onSuccess,
                    billTo,
                    projectInDescription,
                    invoiceRows,
                    invoice,
                    onError,
                });
            }
            else if (isQuickbooksInvoice) {
                exportInvoiceToQuickbooksOnline({
                    intl,
                    onSuccess,
                    billTo,
                    invoice,
                    invoiceRows,
                    quickbookSettings,
                    taxCode: selectedQuickbooksCode,
                    onError,
                });
            }
            else if (isEconomicInvoice) {
                exportInvoiceToEconomics({
                    intl,
                    onSuccess,
                    billTo,
                    invoice,
                    invoiceRows,
                    onError,
                });
            }
        }
    };
    const getProjectIdRenderProps = row => {
        return {
            color: row.projectColor,
            text: row.customProjectId,
        };
    };
    const getExportToName = () => {
        if (isXeroInvoice) {
            return 'Xero';
        }
        if (isQuickbooksInvoice) {
            return 'QuickBooks Online';
        }
        if (isEconomicInvoice) {
            return 'E-conomic';
        }
        return '';
    };
    const onQuickbookSettingChange = (updateIndex) => {
        setQuickbookSettings(quickbookSettings.map((item, index) => {
            if (index === updateIndex) {
                item.checked = !item.checked;
            }
            return item;
        }));
    };
    const getDropdownColumnItems = () => {
        if (isXeroInvoice) {
            return xeroAccounts;
        }
        if (isQuickbooksInvoice) {
            return quickbooksProductsServices;
        }
        if (isEconomicInvoice) {
            return economicProducts;
        }
        return [];
    };
    const disabledTooltipContent = !isReadyForExport ? (React.createElement(FlexColumn, null,
        React.createElement(SubHeading, null,
            React.createElement(FormattedMessage, { id: isXeroInvoice
                    ? 'invoicing.xero.export_disabled_tooltip.title'
                    : isEconomicInvoice
                        ? 'invoicing.economic.export_disabled_tooltip.title'
                        : 'invoicing.quickbooks.export_disabled_tooltip.title' })),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { id: isXeroInvoice
                    ? 'invoicing.xero.export_disabled_tooltip.description'
                    : isEconomicInvoice
                        ? 'invoicing.economic.export_disabled_tooltip.description'
                        : 'invoicing.quickbooks.export_disabled_tooltip.description' })))) : undefined;
    return (React.createElement(Modal, { size: Modal.SIZE.EXTRA_EXTRA_LARGE, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(Heading, { size: 4 },
                React.createElement(FormattedMessage, { id: 'invoicing.export_to', values: { systemName: getExportToName() } }))),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'xxl' },
                React.createElement(FlexRow, { gap: 'l', alignItems: 'start' },
                    React.createElement(FlexColumn, null,
                        React.createElement(SubHeading, null,
                            React.createElement(FormattedMessage, { id: 'common.client' })),
                        React.createElement(Text, { ellipsis: true }, client === null || client === void 0 ? void 0 : client.name)),
                    React.createElement(FlexColumn, null,
                        React.createElement(FlexRow, { alignItems: 'center' },
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'settings_finance.bill_from_entity' })),
                            React.createElement(IconTooltip, { hidden: true },
                                React.createElement(IconTooltip.Item, { title: formatMessage({ id: 'settings_finance.bill_from_entity' }), details: formatMessage({ id: 'invoicing.export.bill_from_entity_tooltip_details' }) }))),
                        React.createElement(Text, { ellipsis: true }, (_e = billTo === null || billTo === void 0 ? void 0 : billTo.billFrom) === null || _e === void 0 ? void 0 : _e.name)),
                    React.createElement(FlexColumn, null,
                        React.createElement(FlexRow, { alignItems: 'center' },
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'settings_finance.bill_to_entity' })),
                            React.createElement(IconTooltip, null,
                                React.createElement(IconTooltip.Item, { title: formatMessage({ id: 'settings_finance.bill_to_entity' }), details: formatMessage({ id: 'invoicing.export.bill_to_entity_tooltip_details' }) }))),
                        React.createElement(Text, { ellipsis: true }, billTo === null || billTo === void 0 ? void 0 : billTo.name)),
                    isXeroInvoice && (React.createElement(FlexColumn, null,
                        React.createElement(SubHeading, null,
                            React.createElement(FormattedMessage, { id: 'integrations.xero.xero_account' }),
                            React.createElement(DropdownList, null,
                                React.createElement(DropdownList.Trigger, null,
                                    React.createElement(ButtonDropdownField, { disabled: loading, isDefaultLabel: !selectedXeroAccount }, selectedXeroAccount
                                        ? selectedXeroAccount.label
                                        : formatMessage({ id: 'common.select' }) +
                                            ' ' +
                                            formatMessage({ id: 'integrations.xero.xero_account' }))),
                                (xeroAccounts === null || xeroAccounts === void 0 ? void 0 : xeroAccounts.length) > 0 && (React.createElement(DropdownList.Content, { offset: 10 }, xeroAccounts.map((xeroAccount, index) => (React.createElement(DropdownList.Item, { key: `xero-${index}`, onClick: () => setSelectedXeroAccount(xeroAccount) }, xeroAccount.label))))))))),
                    isQuickbooksInvoice && (React.createElement(React.Fragment, null,
                        React.createElement(FlexColumn, null,
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'integrations.quickbooks.tax_code' }),
                                React.createElement(DropdownList, null,
                                    React.createElement(DropdownList.Trigger, null,
                                        React.createElement(ButtonDropdownField, { disabled: loading, isDefaultLabel: !selectedQuickbooksCode }, selectedQuickbooksCode
                                            ? selectedQuickbooksCode.label
                                            : formatMessage({ id: 'invoicing.no_tax' }))),
                                    (quickbooksTaxCodes === null || quickbooksTaxCodes === void 0 ? void 0 : quickbooksTaxCodes.length) > 0 && (React.createElement(DropdownList.Content, { offset: 10 }, quickbooksTaxCodes.map((taxCode, index) => (React.createElement(DropdownList.Item, { key: `tax-code-${index}`, onClick: () => setSelectedQuickbooksCode(taxCode) }, taxCode.label)))))))),
                        React.createElement(FlexColumn, null,
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'integrations.quickbooks.items_and_services' }),
                                React.createElement(DropdownList, null,
                                    React.createElement(DropdownList.Trigger, null,
                                        React.createElement(ButtonDropdownField, { disabled: loading, width: '320px', isDefaultLabel: !selectedQuickbooksProductService }, selectedQuickbooksProductService
                                            ? selectedQuickbooksProductService.label
                                            : formatMessage({ id: 'common.select' }) +
                                                ' ' +
                                                formatMessage({ id: 'integrations.quickbooks.items_and_services' }))),
                                    (quickbooksProductsServices === null || quickbooksProductsServices === void 0 ? void 0 : quickbooksProductsServices.length) > 0 && (React.createElement(DropdownList.Content, { offset: 10 }, quickbooksProductsServices.map((productService, index) => (React.createElement(DropdownList.Item, { key: `product-service-${index}`, onClick: () => setSelectedQuickbooksProductService(productService) }, productService.label)))))))))),
                    isEconomicInvoice && (React.createElement(FlexColumn, null,
                        React.createElement(SubHeading, null,
                            React.createElement(FormattedMessage, { id: 'integrations.economic.product' }),
                            React.createElement(DropdownList, null,
                                React.createElement(DropdownList.Trigger, null,
                                    React.createElement(ButtonDropdownField, { disabled: loading, isDefaultLabel: !selectedEconomicProduct }, selectedEconomicProduct
                                        ? selectedEconomicProduct.label
                                        : formatMessage({ id: 'common.select' }) +
                                            ' ' +
                                            formatMessage({ id: 'integrations.economic.product' }))),
                                (economicProducts === null || economicProducts === void 0 ? void 0 : economicProducts.length) > 0 && (React.createElement(DropdownList.Content, { offset: 10 }, economicProducts.map((product, index) => (React.createElement(DropdownList.Item, { key: `economic-product-${index}`, onClick: () => setSelectedEconomicProduct(product) }, product.label)))))))))),
                isQuickbooksInvoice && (React.createElement(FlexRow, null,
                    React.createElement(DropdownList, null,
                        React.createElement(DropdownList.Trigger, null,
                            React.createElement(ButtonDropdown, { emphasis: 'medium', style: { width: 'fit-content' } },
                                React.createElement(FormattedMessage, { id: 'invoicing.quickbooks.quickbook_settings', values: { count: quickbookSettingsCount } }))),
                        React.createElement(DropdownList.Content, { offset: 10, align: 'start' }, quickbookSettings.map((setting, index) => (React.createElement(DropdownList.CheckboxItem, { key: `quickbook-setting-${index}`, onChange: () => onQuickbookSettingChange(index), checked: setting.checked, disabled: setting.disabled }, setting.name))))))),
                isXeroInvoice && (React.createElement(Checkbox, { checked: projectInDescription, onChange: () => setProjectInDescription(!projectInDescription), labelText: formatMessage({ id: 'invoicing.xero.project_in_description' }), disabled: loading })),
                React.createElement(Table, { data: invoiceRows, width: '100%' },
                    React.createElement(Table.ColoredIdIconColumn, { accessorKey: 'project.id', header: formatMessage({ id: 'common.id' }), renderProps: getProjectIdRenderProps }),
                    React.createElement(Table.TextColumn, { accessorKey: 'project.name', header: formatMessage({ id: 'common.project' }) }),
                    React.createElement(Table.TextColumn, { accessorKey: 'entryDescription', width: '232px', header: formatMessage({ id: 'common.description' }) }),
                    React.createElement(Table.DropdownColumn, { header: dropdownColumnLabel, label: row => row.label || '', defaultLabel: formatMessage({ id: 'common.select' }) + ' ' + dropdownColumnLabel, width: '180px' }, getDropdownColumnItems().map((item, index) => (React.createElement(Table.DropdownColumn.ListItem, { key: `row-dropdown-${index}`, onClick: row => {
                            if (isXeroInvoice) {
                                selectExpenseXeroAccount(row, item);
                            }
                            else if (isQuickbooksInvoice) {
                                selectQuickBooksProductService(row, item);
                            }
                            else if (isEconomicInvoice) {
                                selectEconomicProduct(row, item);
                            }
                        }, disabled: loading }, item.label)))),
                    React.createElement(Table.NumberColumn, { accessorKey: 'quantity', header: formatMessage({ id: 'common.quantity' }) }),
                    React.createElement(Table.CurrencyColumn, { currency: (invoice === null || invoice === void 0 ? void 0 : invoice.currency) || '', accessorKey: 'unitPrice', header: formatMessage({ id: 'common.unit_price' }), maximumFractionDigits: 4 }),
                    React.createElement(Table.PercentageColumn, { accessorKey: 'discountPercentage', header: formatMessage({ id: 'project_section.xero_modal_table_discount' }) }),
                    React.createElement(Table.PercentageColumn, { accessorKey: 'taxPercentage', header: formatMessage({ id: 'common.tax' }) }),
                    React.createElement(Table.CurrencyColumn, { currency: (invoice === null || invoice === void 0 ? void 0 : invoice.currency) || '', accessorKey: 'totalPrice', header: formatMessage({ id: 'expense_item_modal.total_price' }) })),
                React.createElement(FlexRow, { alignItems: 'end', alignSelf: 'end' },
                    React.createElement(FlexColumn, { gap: 's' },
                        React.createElement(FlexRow, { gap: 'xxl', justifyContent: 'space-between' },
                            React.createElement(FormattedMessage, { id: 'common.subtotal' }),
                            ":",
                            React.createElement(Text, null, Util.getFormattedNumberWithCurrency(currencySymbol, invoiceTotals.subtotal, intl, noSpace))),
                        React.createElement(FlexRow, { gap: 'xxl', justifyContent: 'space-between' },
                            React.createElement(FormattedMessage, { id: 'project_section.xero_modal_table_discount' }),
                            ":",
                            React.createElement(Text, null, Util.getFormattedNumberWithCurrency(currencySymbol, invoiceTotals.discount, intl, noSpace))),
                        React.createElement(FlexRow, { gap: 'xxl', justifyContent: 'space-between' },
                            React.createElement(FormattedMessage, { id: 'common.tax' }),
                            ":",
                            React.createElement(Text, null, Util.getFormattedNumberWithCurrency(currencySymbol, invoiceTotals.tax, intl, noSpace))),
                        React.createElement(FlexRow, { gap: 'xxl', justifyContent: 'space-between' },
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'common.total_amount' })),
                            React.createElement(SubHeading, null, Util.getFormattedNumberWithCurrency(currencySymbol, invoiceTotals.total, intl, noSpace))))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onSave, tooltipContent: disabledTooltipContent, disabled: !isReadyForExport || loading },
                React.createElement(FormattedMessage, { id: 'common.export' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, disabled: loading },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default createFragmentContainer(ExportInvoiceModal, exportInvoiceModalFragment);
