import React, {useEffect, useMemo, useRef} from 'react';
import {SectionWrapper} from './TimeApprovalPage_styled';
import {APPROVAL_STATUS, BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import TimeApprovalTable from './TimeApprovalTable';
import {Button, Tab, Tabs} from 'web-components';
import {getPersonData, getProjectData, GROUP_BY, ownedProjectPersonsQuery} from './TimeApprovalLogic';
import EmptyState from '../../../forecast-app/shared/components/empty-states/empty_state';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import SendTimeRegEmailMutation from '../../../mutations/send_time_reg_email_mutation';
import {useForecastFetchQuery} from '../../../forecast-app/shared/hooks/useForecastFetchQuery';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {createRefetchContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {groupBy} from 'lodash';
import {usingTimeApproval, usingTimeOffApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import * as tracking from '../../../tracking';
import {getSocketConfig} from './TimeApprovalSocketHandling';
import {hideLoader, showLoader} from '../../../containers/global_loader';
import {isSageUsingTimeRegsFromForecast} from '../TimeManagementUtil';

const applyFilter = (timeReg, filterFunctions, filterOptions) => {
	if (!filterFunctions.timeRegFilter) return true;
	const timeRegPerson = timeReg.person;
	const timeRegProject = timeReg.project || timeReg.task?.project || timeReg.idleTime;
	// Flatten time regs to have them play well with filter
	const flattenedTimeReg = {
		...timeReg,
		project: timeRegProject,
		budgetType: timeRegProject.budgetType,
		person: timeRegPerson,
		role: timeRegPerson.role,
		department: timeRegPerson.department,
	};
	return filterFunctions.timeRegFilter(flattenedTimeReg, filterOptions);
};

const TimeApprovalSection = ({
	viewer,
	relay,
	setSocketVariables,
	setSocketConfig,
	filterFunctions,
	enabledColumns,
	currentViewingDate,
	intl,
	group,
	actualPersonId,
	personFilter,
	filterOptions,
	topLevelApprovalDisabled,
	companyLockedDate,
}) => {
	const {fetch, data} = useForecastFetchQuery(ownedProjectPersonsQuery);

	let timeRegs = viewer.company.timeRegistrations.edges.map(tReg => tReg.node);
	if (filterFunctions) {
		timeRegs = timeRegs.filter(tReg => applyFilter(tReg, filterFunctions, filterOptions));
	}
	const groupedTimeRegs = groupBy(timeRegs, 'approvalStatus');
	const showProjectActions = !isSageUsingTimeRegsFromForecast(viewer);

	/* On socket events we do not show the loader, but we do need to make sure we hide it
	 * because a socket triggered refetch can cancel and replace a variable triggered refetch
	 * causing the loader to never be hidden.
	 * */
	const refetchOnSocketEvent = variables => {
		relay.refetch(variables, null, () => hideLoader());
	};

	useEffect(() => {
		document.title = 'Time Approval - Forecast';
		tracking.trackPage('Time-approval');
		setSocketConfig(getSocketConfig(), refetchOnSocketEvent);
	}, []);

	const didMount = useRef(false);

	/* When the fetch variables change show the loader, refetch and hide the loader on a completed fetch
	 * */
	const refetchOnVariableChange = variables => {
		showLoader();
		relay.refetch(variables, null, () => hideLoader());
		setSocketVariables(variables);
	};

	useEffect(() => {
		// Only run if not mount
		if (!didMount.current) {
			didMount.current = true;
		} else {
			//we need to fetch now the time entries of each person
			const startDate = currentViewingDate.clone().startOf('week').format('YYYY-MM-DD');

			const endDate = currentViewingDate.clone().endOf('week').format('YYYY-MM-DD');

			const useTimeApproval = usingTimeApproval(viewer.company.useTimeApproval);
			const useTimeOffApproval = usingTimeOffApproval(viewer.company.useTimeOffApproval);

			refetchOnVariableChange({
				startDateString: startDate,
				endDateString: endDate,
				approvableTime: useTimeApproval,
				approvableTimeOff: useTimeOffApproval,
			});
		}
	}, [currentViewingDate]);

	useEffect(() => {
		fetch({personId: actualPersonId});
	}, []);

	const sendMail = (personIds, onSuccess) => {
		Util.CommitMutation(
			SendTimeRegEmailMutation,
			{
				receiverIds: personIds,
				dateRange:
					currentViewingDate.clone().startOf('w').format('DD MMMM') +
					' - ' +
					currentViewingDate.clone().endOf('w').format('DD MMMM YYYY'),
			},
			onSuccess
		);
	};
	const handleRemindAll = personIds => {
		const onSuccess = () => {
			createToast({duration: 5000, message: intl.formatMessage({id: 'approval.reminder_sent_to_all'})});
		};
		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: intl.formatMessage({id: 'timesheet.reminder_all_modal_header'}),
			content: <div className={'body-container'}>{intl.formatMessage({id: 'timesheet.reminder_all_modal_body'})}</div>,
			className: 'default-warning-modal',
			buttons: [
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.send'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => sendMail(personIds, onSuccess),
				},
			],
		});
	};

	const handleRemindOne = person => {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: intl.formatMessage(
					{id: 'approval.reminder_sent_to_person'},
					{person: person.firstName || person.lastName}
				),
			});
		};
		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: intl.formatMessage({id: 'timesheet.reminder_modal_header'}),
			content: (
				<div className={'body-container'}>
					{intl.formatMessage(
						{id: 'timesheet.approval_reminder_modal_body'},
						{person: person.firstName + ' ' + person.lastName}
					)}
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.send'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => sendMail([person.id], onSuccess),
				},
			],
		});
	};

	/**
	 * We need to calculate the first groupings for all tab first to get the numbers and the empty states correctly
	 */
	const firstGroupData = useMemo(() => {
		let res = {};
		if (group === GROUP_BY.PROJECT) {
			res[APPROVAL_STATUS.NOT_SUBMITTED] = getProjectData(groupedTimeRegs[APPROVAL_STATUS.NOT_SUBMITTED], null, true);
			res[APPROVAL_STATUS.SUBMITTED] = getProjectData(groupedTimeRegs[APPROVAL_STATUS.SUBMITTED], null, true);
			res[APPROVAL_STATUS.APPROVED] = getProjectData(groupedTimeRegs[APPROVAL_STATUS.APPROVED], null, true);
		} else {
			res[APPROVAL_STATUS.SUBMITTED] = getPersonData(groupedTimeRegs[APPROVAL_STATUS.SUBMITTED]);
			res[APPROVAL_STATUS.APPROVED] = getPersonData(groupedTimeRegs[APPROVAL_STATUS.APPROVED]);
			res[APPROVAL_STATUS.NOT_SUBMITTED] = getPersonData(
				groupedTimeRegs[APPROVAL_STATUS.NOT_SUBMITTED],
				null,
				data?.viewer.company.person.ownedProjectPersons.edges,
				res,
				personFilter,
				filterOptions
			);
		}
		return res;
	}, [data, currentViewingDate, group, groupedTimeRegs]);

	const uniquePersonIdsWithUnsubmittedTimeRegs =
		group === GROUP_BY.PERSON
			? firstGroupData[APPROVAL_STATUS.NOT_SUBMITTED].map(p => p.person.id)
			: getPersonData(groupedTimeRegs[APPROVAL_STATUS.NOT_SUBMITTED]).map(p => p.person.id);
	const submittedCount = firstGroupData[APPROVAL_STATUS.SUBMITTED].length;
	const approvedCount = firstGroupData[APPROVAL_STATUS.APPROVED].length;
	const notSubmittedCount = firstGroupData[APPROVAL_STATUS.NOT_SUBMITTED].length;

	const hasManageAllPermission = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	const children = selectedTab => {
		if (firstGroupData[selectedTab].length === 0) {
			if (submittedCount + approvedCount + notSubmittedCount === 0) {
				return (
					<EmptyState
						pageName={
							hasManageAllPermission
								? EmptyState.EMPTY_STATE.APPROVAL_NO_REGISTERED
								: EmptyState.EMPTY_STATE.APPROVAL_NO_REGISTERED_ON_OWNED
						}
					/>
				);
			} else if (submittedCount + approvedCount === 0) {
				return (
					<EmptyState
						pageName={
							hasManageAllPermission
								? EmptyState.EMPTY_STATE.APPROVAL_NO_SUBMIT
								: EmptyState.EMPTY_STATE.APPROVAL_NO_SUBMIT_ON_OWNED
						}
					/>
				);
			} else if (submittedCount === 0 && approvedCount > 0 && notSubmittedCount > 0) {
				return <EmptyState pageName={EmptyState.EMPTY_STATE.APPROVAL_MISSING_SUBMITION} />;
			} else if (approvedCount === 0) {
				return <EmptyState pageName={EmptyState.EMPTY_STATE.APPROVAL_NOTHING_APPROVED} />;
			} else if (submittedCount + notSubmittedCount === 0) {
				return <EmptyState pageName={EmptyState.EMPTY_STATE.APPROVAL_ALL_APPROVED} />;
			} else if (submittedCount > 0 && notSubmittedCount === 0) {
				return <EmptyState pageName={EmptyState.EMPTY_STATE.APPROVAL_ALL_SUBMITTED} />;
			}
		} else {
			return (
				<>
					<div
						style={{
							position: 'sticky',
							left: '0',
							padding: '16px 16px',
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						{selectedTab === APPROVAL_STATUS.NOT_SUBMITTED && (
							<Button
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								onClick={() => handleRemindAll(uniquePersonIdsWithUnsubmittedTimeRegs)}
							>
								{intl.formatMessage({id: 'timesheet.reminder_all_modal_header'})}
							</Button>
						)}
					</div>
					<TimeApprovalTable
						onRemind={handleRemindOne}
						sectionType={selectedTab}
						currentViewingDate={currentViewingDate}
						intl={intl}
						enabledColumns={enabledColumns}
						isGroupedByProject={group === GROUP_BY.PROJECT}
						firstGroupData={firstGroupData[selectedTab]}
						topLevelApprovalDisabled={topLevelApprovalDisabled}
						actualPersonId={actualPersonId}
						companyLockedDate={companyLockedDate}
						showProjectActions={showProjectActions}
					/>
				</>
			);
		}
	};

	return (
		<SectionWrapper>
			<Tabs>
				<Tab
					cy={'time-approval-tab-submitted'}
					defaultSelected
					title={
						intl.formatMessage({id: 'time_approval.submitted'}) +
						' (' +
						firstGroupData[APPROVAL_STATUS.SUBMITTED].length +
						')'
					}
				>
					<CustomScrollDiv>{children(APPROVAL_STATUS.SUBMITTED)}</CustomScrollDiv>
				</Tab>
				<Tab
					cy={'time-approval-tab-not-submitted'}
					title={
						intl.formatMessage({id: 'time_approval.not_submitted'}) +
						' (' +
						firstGroupData[APPROVAL_STATUS.NOT_SUBMITTED].length +
						')'
					}
				>
					<CustomScrollDiv>{children(APPROVAL_STATUS.NOT_SUBMITTED)}</CustomScrollDiv>
				</Tab>
				<Tab
					cy={'time-approval-tab-approved'}
					title={
						intl.formatMessage({id: 'common.approved'}) +
						' (' +
						firstGroupData[APPROVAL_STATUS.APPROVED].length +
						')'
					}
				>
					<CustomScrollDiv>{children(APPROVAL_STATUS.APPROVED)}</CustomScrollDiv>
				</Tab>
			</Tabs>
		</SectionWrapper>
	);
};

const TimeApprovalSectionQuery = graphql`
	query TimeApprovalSection_Query(
		$searchQuery: TaskSearchQueryType
		$startDateString: String
		$endDateString: String
		$approvableTime: Boolean
		$approvableTimeOff: Boolean
	) {
		viewer {
			actualPersonId
			projectOwner
			component(name: "time-approval-section")
			...TimeApprovalSection_viewer
				@arguments(
					searchQuery: $searchQuery
					startDateString: $startDateString
					endDateString: $endDateString
					approvableTime: $approvableTime
					approvableTimeOff: $approvableTimeOff
				)
		}
	}
`;

export {TimeApprovalSectionQuery};

export default withRouter(
	injectIntl(
		withSocketHandling(
			createRefetchContainer(
				TimeApprovalSection,
				{
					viewer: graphql`
						fragment TimeApprovalSection_viewer on Viewer
						@argumentDefinitions(
							searchQuery: {type: "TaskSearchQueryType"}
							startDateString: {type: "String"}
							endDateString: {type: "String"}
							approvableTime: {type: "Boolean"}
							approvableTimeOff: {type: "Boolean"}
						) {
							component(name: "time-approval-section")
							id
							company {
								id
								useTimeApproval
								useTimeOffApproval
								useInternalTimeApproval
								sageIntacctConnectionEnabled
								sageIntacctTimeRegsFromForecast
								modules {
									moduleType
								}
								timeRegistrations(
									first: 10000000
									startDate: $startDateString
									endDate: $endDateString
									searchQuery: $searchQuery
									approvableTime: $approvableTime
									approvableTimeOff: $approvableTimeOff
									validateAccess: true
								) @connection(key: "Company_timeRegistrations", filters: []) {
									edges {
										node {
											id
											approvalStatus
											noApprovalStatusChange
											day
											month
											year
											minutesRegistered
											billableMinutesRegistered
											billable
											notes
											role {
												id
												name
											}
											idleTime {
												id
												name
												isInternalTime
											}
											person {
												id
												firstName
												lastName
												fullName
												email
												profilePictureId
												startDate
												endDate
												monday
												tuesday
												wednesday
												thursday
												friday
												saturday
												sunday
												department {
													id
												}
												role {
													id
												}
											}
											project {
												id
												name
												projectColor
												companyProjectId
												customProjectId
												fullAccessToProject
												budgetType
												billable
												client {
													id
													name
												}
												projectPersons(first: 100, contactsOnly: true) {
													edges {
														node {
															person {
																id
																fullName
																profilePictureId
															}
														}
													}
												}
											}
											task {
												id
												name
												companyTaskId
												estimateForecast
												billable
												estimateForecastMinutes
												totalMinutesRegistered
												project {
													id
													name
													companyProjectId
													customProjectId
													projectColor
													fullAccessToProject
													estimationUnit
													minutesPerEstimationPoint
													budgetType
													billable
													client {
														id
														name
													}
													projectPersons(first: 100, contactsOnly: true) {
														edges {
															node {
																person {
																	id
																	fullName
																	profilePictureId
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					`,
				},
				graphql`
					query TimeApprovalSectionRefetchQuery(
						$searchQuery: TaskSearchQueryType
						$startDateString: String
						$endDateString: String
						$approvableTime: Boolean
						$approvableTimeOff: Boolean
					) {
						viewer {
							component(name: "time-approval-section")
							...TimeApprovalSection_viewer
								@arguments(
									searchQuery: $searchQuery
									startDateString: $startDateString
									endDateString: $endDateString
									approvableTime: $approvableTime
									approvableTimeOff: $approvableTimeOff
								)
						}
					}
				`
			)
		)
	)
);
