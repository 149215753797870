/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExportInvoiceButton_company$ref: FragmentReference;
declare export opaque type ExportInvoiceButton_company$fragmentType: ExportInvoiceButton_company$ref;
export type ExportInvoiceButton_company = {|
  +newXeroEnabled: ?boolean,
  +quickbooksEnabled: ?boolean,
  +economicEnabled: ?boolean,
  +quickbooksRealmId: ?string,
  +xeroShortCode: ?string,
  +sageIntacctConnectionEnabled: ?boolean,
  +clients: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +xeroContactId: ?string,
        +economicCustomerId: ?string,
        +quickbooksCustomerId: ?string,
        +quickbooksRealmId: ?string,
        +sageIntacctCustomerId: ?string,
      |}
    |}>
  |},
  +$refType: ExportInvoiceButton_company$ref,
|};
export type ExportInvoiceButton_company$data = ExportInvoiceButton_company;
export type ExportInvoiceButton_company$key = {
  +$data?: ExportInvoiceButton_company$data,
  +$fragmentRefs: ExportInvoiceButton_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quickbooksRealmId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExportInvoiceButton_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newXeroEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quickbooksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "economicEnabled",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xeroShortCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientTypeConnection",
      "kind": "LinkedField",
      "name": "clients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "xeroContactId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "economicCustomerId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quickbooksCustomerId",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sageIntacctCustomerId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a1900ce3d311b7849e3ef349b052826';

module.exports = node;
