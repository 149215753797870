/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query SageIntacctExportTasksModal_Query(
  $projectId: ID!
) {
  viewer {
    actualPersonId
    component(name: "sage_intacct_export_tasks_modal")
    ...SageIntacctExportTasksModal_viewer_2CgS8h
    id
  }
}

fragment SageIntacctExportTasksModal_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    id
    name
    sageProject {
      sageProjectId
      id
    }
    tasks(first: 10000) {
      edges {
        node {
          id
          name
          sageIntacctId
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "ID!"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "sage_intacct_export_tasks_modal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"sage_intacct_export_tasks_modal\")"
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SageIntacctExportTasksModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "projectId",
                                    "variableName": "projectId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "SageIntacctExportTasksModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SageIntacctExportTasksModal_Query",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "internalId",
                                    "variableName": "projectId"
                                }
                            ],
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SageProjectType",
                                    "kind": "LinkedField",
                                    "name": "sageProject",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "sageProjectId",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "TaskTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "tasks",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TaskTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Task",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "sageIntacctId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "tasks(first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "Project_tasks",
                                    "kind": "LinkedHandle",
                                    "name": "tasks"
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "SageIntacctExportTasksModal_Query",
            "operationKind": "query",
            "text": "query SageIntacctExportTasksModal_Query(\n  $projectId: ID!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"sage_intacct_export_tasks_modal\")\n    ...SageIntacctExportTasksModal_viewer_2CgS8h\n    id\n  }\n}\n\nfragment SageIntacctExportTasksModal_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    id\n    name\n    sageProject {\n      sageProjectId\n      id\n    }\n    tasks(first: 10000) {\n      edges {\n        node {\n          id\n          name\n          sageIntacctId\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = 'f95f5a66bf5e9c0f307ba784a3587857';
export default node;
