import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Styled from 'styled-components/macro';
import { DragHandleIcon } from 'web-components';
import InputFieldV2 from '../../../../components/new-ui/input_field';
import trashIcon from '../../../../images/v2/trash.svg';
const Item = Styled.div `
  background-color: 'none';
  left: 52px;
  display:flex;
  height: 40px;
  width: 350px;
  gap: 0px;
`;
const Title = Styled.div `
  width: 420px;
  height: 40px;
  overflow: hidden;
`;
const TrashIcon = Styled.div `
	width: 20px;
	height: 20px;
	background-image: url(${trashIcon});
	margin: 10px;
`;
const DragHandle = Styled.div `
	padding: 14px;
	width: 34px;
	align-self: center;
`;
const TrashButton = Styled.button `
	margin-left: 10
	padding: 0px;
	border: 0px;
`;
const OrderedItem = React.forwardRef((props, ref) => {
    const { item, index, onDescriptionChanged, onRemove, disabled } = props;
    return (React.createElement(Draggable, { isDragDisabled: disabled, draggableId: item.level + '', index: index, ref: ref }, provided => (React.createElement(Item, Object.assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef }),
        React.createElement(DragHandle, null,
            React.createElement(DragHandleIcon, { size: DragHandleIcon.SIZE.STANDARD, color: "#4a4a4a" })),
        React.createElement(Title, null,
            React.createElement(InputFieldV2, { locked: disabled, value: item.description, hideLabel: true, type: "text", onChange: value => onDescriptionChanged(value) })),
        React.createElement(TrashButton, { type: "button", disabled: disabled, onClick: onRemove },
            React.createElement(TrashIcon, null))))));
});
export default OrderedItem;
