import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Styled from 'styled-components/macro';
import { Background } from './Background';
import OrderedItem from './OrderedItem';
const ListContainer = Styled.form `
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 0px;
	width: 420px;
`;
const DraggableContainer = Styled.div `
	width: fit-content;
	height: fit-content;
    z-index: 2;
`;
const DroppableContainer = Styled.div `
 	gap: 12px;
  	display: flex;
  	flex-direction: column;
`;
const OrderedList = ({ items, onOrderChanged, onDescriptionChanged, onRemoveItem, disabled, }) => {
    return (React.createElement(ListContainer, { disabled: disabled, style: { height: items.length * 52 } },
        React.createElement(Background, { disabled: disabled, rows: items.length }),
        React.createElement(DraggableContainer, null,
            React.createElement(DragDropContext, { onDragEnd: data => onOrderChanged(data) },
                React.createElement(Droppable, { droppableId: "skills" }, provided => (React.createElement(DroppableContainer, { ref: provided.innerRef },
                    items.map((item, index) => (React.createElement(OrderedItem, { disabled: disabled, key: item.level + '', item: item, index: index, onDescriptionChanged: value => onDescriptionChanged(index, value), onRemove: () => onRemoveItem(index) }))),
                    provided.placeholder)))))));
};
export default OrderedList;
