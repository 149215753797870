import React, {useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Dropdown, KebabMenuIcon, Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import ContextMenu from '../../../../forecast-app/shared/components/context-menus/context_menu';
import ExportInvoiceButton from './ExportInvoiceButton';
import {createFragmentContainer, graphql} from 'react-relay';
import DirectApi from '../../../../directApi';
import {useInvoiceContextMenu} from './useInvoiceContextMenu';
import {changeStatus, handleCsvDownloadPress, preProcessInvoiceData} from './InvoiceUtil';
import {beautifyExternalStatusText, getActiveIntegrationName} from './InvoiceExportUtil';
import {getPaymentStatusText} from '../../../../components/insights/invoicing/InvoicingUtil';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
/*
 * We need to update to the new design system when is has been implemented
 * */

const ProjectInvoicingTable = ({
	project,
	company,
	filterFunctions,
	searchFilterValue,
	theEyeOptions,
	onEditInvoice,
	onDeleteInvoice,
	onUnlinkInvoice,
	exportCsvRef,
	showExport,
}) => {
	const intl = useIntl();

	// Use company currency if project has no rate card
	const currency = project.rateCard ? project.rateCard.currency : company.currency;

	const allInvoices = project.invoices.edges.map(edge => edge.node);
	const filteredInvoices = useMemo(
		() => allInvoices.filter(filterFunctions.invoiceFilter),
		[project.invoices, filterFunctions.invoiceFilter]
	);
	const invoices = useMemo(() => {
		return filteredInvoices.filter(invoice => {
			// filter by search criteria
			const nameMatch = invoice.name.toLowerCase().includes(searchFilterValue.toLowerCase());
			return nameMatch;
		});
	}, [filteredInvoices, searchFilterValue]);

	const userCanUnlinkInvoice = company.integrations.userCanUnlinkInvoice;
	const {openContextMenu, closeContextMenu, getContextMenuOptions, contextMenuData} = useInvoiceContextMenu(
		onEditInvoice,
		onDeleteInvoice,
		userCanUnlinkInvoice,
		onUnlinkInvoice
	);

	const isXeroUsed = company.newXeroEnabled;
	const isEconomicUsed = company.economicEnabled;
	const isQuickbooksUsed = company.quickbooksEnabled;
	const isSageIntacctUsed = company.sageIntacctConnectionEnabled;
	const hasIntegrations = isXeroUsed || isQuickbooksUsed || isEconomicUsed || isSageIntacctUsed;

	const totalInvoiced = invoices.reduce((acc, invoice) => acc + invoice.invoicedWithoutTaxProjectCurrency, 0);
	const invoicesWithPayments = invoices.filter(invoice => invoice.paidWithoutTax > 0);
	const totalPaid = invoicesWithPayments.reduce((acc, invoice) => acc + invoice.paidWithoutTaxProjectCurrency, 0);
	const totalUnpaid = totalInvoiced - totalPaid;
	const headerData = {
		rows: [
			{
				totalInvoiced,
				totalPaid,
				totalUnpaid,
			},
		],
	};

	const tableData = {
		rows: invoices,
	};

	const [xeroTenantToShortCode, setXeroTenantToShortCode] = useState(null);
	useEffect(() => {
		if (company.newXeroEnabled && xeroTenantToShortCode == null) {
			DirectApi.Fetch('xero/shortCodes').then(data => {
				setXeroTenantToShortCode(data);
			});
		}
	});

	const showColumn = columnName => {
		return theEyeOptions?.find(eo => eo.name === columnName)?.checked;
	};

	const tableColumns = {
		invoiceId: true,
		invoiceName: true,
		client: showColumn('client'),
		status: showColumn('status'),
		paymentStatus: showColumn('status') && !hasIntegrations,
		reference: showColumn('reference'),
		date: showColumn('invoiceDate'),
		invoicedAmount: showColumn('total_invoiced'),
		paidAmount: showColumn('paid'),
		unpaidAmount: showColumn('unpaid'),
		dueDate: showColumn('dueDate'),
		integrationLink: hasIntegrations,
		hasContextMenu: true,
	};
	if (showColumn('status') && company.newXeroEnabled) {
		tableColumns.xeroStatus = true;
	} else if (showColumn('status') && company.quickbooksEnabled) {
		tableColumns.quickbooksStatus = true;
	} else if (showColumn('status') && company.economicEnabled) {
		tableColumns.economicStatus = true;
	} else if (showColumn('status') && company.sageIntacctConnectionEnabled) {
		tableColumns.sageIntacctStatus = true;
	}

	useImperativeHandle(exportCsvRef, () => {
		return {
			exportCsv: () => handleCsvDownloadPress(project.id, invoices, tableColumns, intl),
		};
	});

	return (
		<>
			{!!contextMenuData && (
				<div style={{zIndex: 100}}>
					<ContextMenu
						options={getContextMenuOptions(contextMenuData.invoiceId, invoices)}
						contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
						closeContextMenu={closeContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				</div>
			)}
			<Table>
				<Table.Header>
					<Table.HeaderColumn usePadding align="left" visible width="SMALL">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.id'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn flex={1} usePadding align="left" visible width="LARGE">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'project_section.invoice_name'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<Table.HeaderColumn usePadding align="left" visible={tableColumns.status} width="MEDIUM">
						<Table.HeaderColumn.Title>
							{!hasIntegrations
								? intl.formatMessage({id: 'common.status'})
								: hasFeatureFlag('invoice_external_status')
								? hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)
									? intl.formatMessage({id: 'invoicing.sage_psa_status'})
									: intl.formatMessage({id: 'invoicing.forecast_status'})
								: intl.formatMessage({id: 'common.status'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					{hasIntegrations && hasFeatureFlag('invoice_external_status') ? (
						<Table.HeaderColumn
							usePadding
							align="left"
							visible={
								tableColumns.xeroStatus ||
								tableColumns.quickbooksStatus ||
								tableColumns.economicStatus ||
								tableColumns.sageIntacctStatus
							}
							width="MEDIUM"
						>
							<Table.HeaderColumn.Title>
								{getActiveIntegrationName(isXeroUsed, isEconomicUsed, isQuickbooksUsed, isSageIntacctUsed)}{' '}
								Status
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					) : (
						<Table.HeaderColumn usePadding align="left" visible={tableColumns.paymentStatus} width="MEDIUM_SMALL">
							<Table.HeaderColumn.Title>
								{intl.formatMessage({id: 'invoicing.payment_status'})}
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					)}
					<Table.HeaderColumn usePadding align="left" visible={tableColumns.reference} width="SMALL">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.reference'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={tableColumns.date} width="MEDIUM">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'invoicing.invoice_date'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={tableColumns.dueDate} width="MEDIUM">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.due_date'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={tableColumns.invoicedAmount} width="MEDIUM">
						<Table.HeaderColumn.Title>
							<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.invoiced_tooltip'})}>
								{intl.formatMessage({id: 'common.total_invoiced'})}
							</ForecastTooltip>
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={tableColumns.paidAmount} width="MEDIUM">
						<Table.HeaderColumn.Title>
							<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.paid_tooltip'})}>
								{intl.formatMessage({id: 'settings_subscription.paid'})}
							</ForecastTooltip>
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={tableColumns.unpaidAmount} width="MEDIUM">
						<Table.HeaderColumn.Title>
							<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.unpaid_tooltip'})}>
								{intl.formatMessage({id: 'settings_subscription.unpaid'})}
							</ForecastTooltip>
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<Table.HeaderColumn
						usePadding
						align={'center'}
						visible={tableColumns.integrationLink && showExport}
						width={'MEDIUM'}
					>
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.export'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<Table.HeaderColumn align={'center'} visible={true} width={'EXTRA_SMALL'}>
						{/* context  menu*/}
					</Table.HeaderColumn>
				</Table.Header>

				<Table.Rows data={tableData} canExpand={false}>
					{({rowData, tableColumnsProps}) => {
						const invoiceData = preProcessInvoiceData(rowData, project, company);
						return (
							<Table.Row {...tableColumnsProps}>
								<Table.Column>
									<Table.Column.Cell onClick={() => onEditInvoice(invoiceData.companyInvoiceId)}>
										{invoiceData.companyInvoiceId}
									</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell onClick={() => onEditInvoice(invoiceData.companyInvoiceId)}>
										{invoiceData.name}
									</Table.Column.Cell>
								</Table.Column>

								<Table.Column userpilot={'status-dropdown'}>
									<Dropdown
										size="large"
										disabled={invoiceData.isExported}
										usePortal
										onSelect={changeStatus(invoiceData.id)}
										dropdownAlignment={'center'}
										maxHeight={80}
										width={128}
										name={intl.formatMessage({id: 'common.status'})}
										selectedItems={
											!invoiceData.isExported
												? [invoiceData.status]
												: hasFeatureFlag('invoice_external_status')
												? 'EXPORTED'
												: [invoiceData.status]
										}
									>
										<Dropdown.SingleText
											value="DRAFT"
											searchString={intl.formatMessage({id: 'common.draft'})}
										>
											{intl.formatMessage({id: 'common.draft'})}
										</Dropdown.SingleText>
										<Dropdown.SingleText
											value="APPROVED"
											searchString={intl.formatMessage({id: 'common.approved'})}
										>
											{intl.formatMessage({id: 'common.approved'})}
										</Dropdown.SingleText>
										<Dropdown.SingleText
											value="SENT"
											searchString={intl.formatMessage({id: 'common.sent'})}
										>
											{intl.formatMessage({id: 'common.sent'})}
										</Dropdown.SingleText>
										{hasIntegrations && hasFeatureFlag('invoice_external_status') ? (
											<Dropdown.SingleText
												value="EXPORTED"
												searchString={intl.formatMessage({id: 'common.exported'})}
											>
												{intl.formatMessage({id: 'common.exported'})}
											</Dropdown.SingleText>
										) : (
											<></>
										)}
									</Dropdown>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>
										{hasIntegrations
											? invoiceData.isExported && hasFeatureFlag('invoice_external_status')
												? beautifyExternalStatusText(invoiceData.externalStatus)
												: ''
											: getPaymentStatusText(invoiceData.paymentStatus, intl)}
									</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>{invoiceData.invoiceReference}</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>{invoiceData.invoiceDate.format('D MMM YYYY')}</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>{invoiceData.dueDate.format('D MMM YYYY')}</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(invoiceData.currency),
											invoiceData.invoicedWithoutTax,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(invoiceData.currency),
											invoiceData.paidWithoutTax,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>

								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(invoiceData.currency),
											invoiceData.invoicedWithoutTax - invoiceData.paidWithoutTax,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>

								<Table.Column>
									<div style={{display: 'flex', alignItems: 'center'}}>
										<ExportInvoiceButton
											company={company}
											project={project}
											invoiceId={invoiceData.id}
											xeroTenantToShortCode={xeroTenantToShortCode}
										/>
									</div>
								</Table.Column>

								<Table.Column>
									<KebabMenuIcon
										onClick={e => openContextMenu(e, invoiceData.id, invoiceData.companyInvoiceId)}
										cy={'invoice-row-context-menu'}
										userpilot={tableColumnsProps.index === 0 ? 'context-menu-wrapper-invoices' : null}
									/>
								</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
				<Table.Rows data={headerData} canExpand={false} hasHeader>
					{({rowData: totalsData, tableColumnsProps}) => {
						return (
							<Table.Row {...tableColumnsProps}>
								<Table.Column />
								<Table.Column>{intl.formatMessage({id: 'common.totals'})}</Table.Column>

								<Table.Column />
								<Table.Column />
								<Table.Column />
								<Table.Column />
								<Table.Column />
								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(currency),
											totalsData.totalInvoiced,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(currency),
											totalsData.totalPaid,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>
								<Table.Column>
									<Table.Column.Cell>
										{Util.getFormattedNumberWithCurrency(
											Util.GetCurrencySymbol(currency),
											totalsData.totalUnpaid,
											intl
										)}
									</Table.Column.Cell>
								</Table.Column>

								<Table.Column>{/* integration button */}</Table.Column>
								<Table.Column>{/* context  menu*/}</Table.Column>
							</Table.Row>
						);
					}}
				</Table.Rows>
			</Table>
		</>
	);
};

export default createFragmentContainer(ProjectInvoicingTable, {
	company: graphql`
		fragment ProjectInvoicingTable_company on Company {
			currency
			newXeroEnabled
			quickbooksEnabled
			economicEnabled
			sageIntacctConnectionEnabled
			integrations {
				userCanUnlinkInvoice
			}
			...ExportInvoiceButton_company
		}
	`,
	project: graphql`
		fragment ProjectInvoicingTable_project on ProjectType {
			id
			name
			rateCard {
				currency
			}
			budget
			budgetType
			...ExportInvoiceButton_project
			invoices {
				edges {
					node {
						invoicedWithoutTax
						invoicedWithoutTaxProjectCurrency
						paidWithoutTax
						paidWithoutTaxProjectCurrency
						quickbooksId
						sageIntacctId
						economicId
						xeroId
						id
						invoiceReference
						companyInvoiceId
						currency
						status
						externalStatus
						quickbooksRealmId
						paymentStatus
						createdYear
						createdMonth
						createdDay
						dueYear
						dueMonth
						dueDay
						name
						client {
							id
							name
						}
					}
				}
			}
		}
	`,
});
