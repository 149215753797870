import {createFragmentContainer, graphql} from 'react-relay';
import React from 'react';
import * as tracking from '../../../../tracking';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {Button} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {EconomicIcon, QuickbooksIcon, SageIntacctIcon, XeroIcon} from 'web-components';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';

const sortClients = function (a, b) {
	const labelA = a.label.toUpperCase();
	const labelB = b.label.toUpperCase();
	if (labelA < labelB) {
		return -1;
	}
	if (labelA > labelB) {
		return 1;
	}
	return 0;
};

const onExportClick = (invoice, company) => {
	if (hasFeatureFlag('invoicing_pathways')) {
		showModal({
			type: MODAL_TYPE.EXPORT_INVOICE_BILLING_OPTION,
			invoiceId: invoice.id,
		});
	} else if (!invoice.xeroId && !invoice.quickbooksId && !invoice.economicId) {
		// Show invoice export modal

		// Xero
		if (company.newXeroEnabled) {
			tracking.trackEvent('Export to Xero pressed');
			trackEvent('Export To Xero', 'Clicked');
			showModal({
				type: MODAL_TYPE.XERO_EXPORT,
				invoiceId: invoice.id,
				xeroClients: company.clients.edges
					.filter(edge => edge.node.xeroContactId)
					.map(edge => ({
						value: edge.node.id,
						label: edge.node.name,
						xeroContactId: edge.node.xeroContactId,
					}))
					.sort(sortClients),
			});
		}
		// Sage Intacct
		else if (company.sageIntacctConnectionEnabled) {
			tracking.trackEvent('Export to Sage Intacct pressed');
			trackEvent('Export To Sage Intacct', 'Clicked');
			showModal({
				type: MODAL_TYPE.SAGE_INTACCT_EXPORT,
				invoiceId: invoice.id,
				sageIntacctCustomers: company.clients.edges
					.filter(edge => edge.node.sageIntacctCustomerId)
					.map(edge => ({value: edge.node.sageIntacctCustomerId, label: edge.node.name}))
					.sort(sortClients),
			});
		}
		// E-conomic
		else if (company.economicEnabled) {
			tracking.trackEvent('Export to E-conomic pressed');
			trackEvent('Export To Economic', 'Clicked');
			showModal({
				type: MODAL_TYPE.ECONOMIC_EXPORT,
				invoiceId: invoice.id,
				economicCustomers: company.clients.edges
					.filter(edge => edge.node.economicCustomerId)
					.map(edge => ({value: edge.node.id, label: edge.node.name}))
					.sort(sortClients),
			});
		}
		// QuickBooks
		else if (company.quickbooksEnabled) {
			tracking.trackEvent('Export to QuickBooks pressed');
			trackEvent('Export To Quickbooks', 'Clicked');
			showModal({
				type: MODAL_TYPE.QUICKBOOKS_EXPORT,
				invoiceId: invoice.id,
				quickbooksCustomers: company.clients.edges
					.filter(edge => edge.node.quickbooksCustomerId)
					.map(edge => ({value: edge.node.id, label: edge.node.name, realmId: edge.node.quickbooksRealmId}))
					.sort(sortClients),
			});
		}
	}
};
const ExportInvoiceButton = ({company, project, invoiceId, xeroTenantToShortCode, invoice}) => {
	const {formatMessage} = useIntl();
	const projectInvoice = project?.invoices?.edges?.find(e => e?.node?.id === invoiceId)?.node;
	const {xeroId, xeroTenantId, quickbooksId, economicId, sageIntacctId, status, quickbooksRealmId} =
		projectInvoice || invoice;

	if (xeroId) {
		let xeroShortCode;
		if (xeroTenantToShortCode) {
			xeroShortCode = xeroTenantToShortCode[xeroTenantId];
		}

		return (
			<ForecastTooltip content={formatMessage({id: 'invoicing.open_invoice_in'}, {systemName: 'Xero'})} delay={[50, 100]}>
				<XeroIcon
					onClick={() => {
						if (xeroShortCode) {
							window.open(
								`https://go.xero.com/organisationlogin/default.aspx?shortcode=${xeroShortCode}&redirecturl=/AccountsReceivable/Edit.aspx?InvoiceID=${xeroId}`,
								'_blank'
							);
						} else {
							window.open(`https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID=${xeroId}`, '_blank');
						}
					}}
				/>
			</ForecastTooltip>
		);
	} else if (sageIntacctId) {
		return (
			<ForecastTooltip
				content={formatMessage({id: 'invoicing.open_invoice_in'}, {systemName: 'Sage Intacct'})}
				delay={[50, 100]}
				disabled={true} // Seems like ther is not simple way to construct the link... need to figure this out. For now it's disabled and it just shows the logo.
			>
				<SageIntacctIcon
					// onClick={() => {
					// 	window.open(`#`, '_blank');
					// }}
					size={SageIntacctIcon.SIZE.MEDIUM}
				/>
			</ForecastTooltip>
		);
	} else if (quickbooksId) {
		return (
			<ForecastTooltip
				content={formatMessage({id: 'invoicing.open_invoice_in'}, {systemName: 'QuickBooks'})}
				delay={[50, 100]}
			>
				<QuickbooksIcon
					onClick={() => {
						window.open(
							`${process.env.QUICKBOOKS_BASE_URL}/app/invoice?deeplinkcompanyid=${
								quickbooksRealmId ? quickbooksRealmId : company.quickbooksRealmId
							}&txnId=${quickbooksId}`,
							'_blank'
						);
					}}
					size={QuickbooksIcon.SIZE.STANDARD}
				/>
			</ForecastTooltip>
		);
	} else if (economicId) {
		return (
			<ForecastTooltip
				content={formatMessage({id: 'invoicing.open_invoice_in'}, {systemName: 'E-conomic'})}
				delay={[50, 100]}
			>
				<EconomicIcon
					onClick={() => {
						if (status === 'DRAFT' || status === 'EXPORTED') {
							window.open(`https://secure.e-conomic.com/sales/invoicing/invoices`, '_blank');
						} else if (status === 'APPROVED') {
							window.open(`https://secure.e-conomic.com/sales/invoicing/archive`, '_blank');
						} else if (status === 'SENT') {
							window.open(`https://secure.e-conomic.com/sales/invoicing/sent`, '_blank');
						}
					}}
					size={EconomicIcon.SIZE.STANDARD}
				/>
			</ForecastTooltip>
		);
	} else {
		return (
			<Button emphasis={'low'} onClick={() => onExportClick(projectInvoice || invoice, company)}>
				{formatMessage({id: 'common.export'})}
			</Button>
		);
	}
};
export default createFragmentContainer(ExportInvoiceButton, {
	project: graphql`
		fragment ExportInvoiceButton_project on ProjectType {
			id
			invoices {
				edges {
					node {
						id
						xeroId
						xeroTenantId
						quickbooksId
						economicId
						sageIntacctId
						quickbooksRealmId
						status
					}
				}
			}
		}
	`,
	company: graphql`
		fragment ExportInvoiceButton_company on Company {
			newXeroEnabled
			quickbooksEnabled
			economicEnabled
			quickbooksRealmId
			xeroShortCode
			sageIntacctConnectionEnabled
			clients {
				edges {
					node {
						id
						name
						xeroContactId
						economicCustomerId
						quickbooksCustomerId
						quickbooksRealmId
						sageIntacctCustomerId
					}
				}
			}
		}
	`,
});
