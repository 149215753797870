import React, { useState } from 'react';
import styled from 'styled-components';
import { personIsVirtualUser } from '../../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../../forecast-app/shared/util/util';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
const StatusContainer = styled.div `
	display: flex;
	align-items: flex-end;
	margin-top: 16px;
	gap: 16px;
`;
const StatusLabelContainer = styled.div `
	display: flex;
	flex-direction: column;
`;
const StatusLabel = styled.label `
	font-family: 'Inter';
	color: #858585;
	font-size: 12px;
	white-space: nowrap;
`;
const StatusText = styled.div `
	font-family: 'Inter';
	font-size: 16px;
	height: 34px;
	line-height: 34px;
	white-space: nowrap;
`;
const StatusButton = styled.button `
	font-family: 'Inter';
	font-size: 12px;
	line-height: 16px;
	padding: 8px 12px;
	color: #393946;
	border: 1px solid #e7e7f3;
	border-radius: 4px;
	white-space: nowrap;
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;
const isNotInvited = (person) => person.active && !person.invited && !person.hasLoggedIn;
const isPending = (person) => person.active && person.invited && !person.hasLoggedIn;
const isActive = (person) => person.active && !isNotInvited(person) && !isPending(person);
const isDeactivated = (person) => !person.active;
const getStatusText = (person) => {
    if (isDeactivated(person)) {
        return 'Deactivated';
    }
    if (isActive(person) || personIsVirtualUser(person)) {
        return 'Active';
    }
    if (isNotInvited(person)) {
        return 'Awaiting invitation';
    }
    if (isPending(person)) {
        return 'Pending';
    }
};
const activate = (person) => {
    Util.CommitMutation(UpdatePersonMutation, {
        id: person.id,
        active: true,
    });
};
const deactivate = (person) => {
    Util.CommitMutation(UpdatePersonMutation, {
        id: person.id,
        active: false,
    });
};
const invite = (person, callback) => {
    Util.CommitMutation(UpdatePersonMutation, { id: person.id, invited: true }, callback);
};
const TeamMemberStatusSection = ({ person }) => {
    const [inviting, setInviting] = useState(false);
    const invitePerson = () => {
        setInviting(true);
        invite(person, () => {
            setInviting(false);
        });
    };
    const canInvite = person.email && !personIsVirtualUser(person);
    return (React.createElement(StatusContainer, null,
        React.createElement(StatusLabelContainer, null,
            React.createElement(StatusLabel, null, "Team member status"),
            React.createElement(StatusText, { "data-cy": 'member-status' }, getStatusText(person))),
        !canInvite ? null : isNotInvited(person) ? (React.createElement(StatusButton, { disabled: inviting, onClick: invitePerson }, "Send Invite")) : isPending(person) ? (React.createElement(StatusButton, { "data-cy": 'resend-button', disabled: inviting, onClick: invitePerson }, "Resend Invite")) : null,
        isDeactivated(person) ? (React.createElement(StatusButton, { "data-cy": 'activate-button', onClick: () => {
                activate(person);
            } }, "Activate")) : (React.createElement(StatusButton, { "data-cy": 'deactivate-button', onClick: () => {
                deactivate(person);
            } }, "Deactivate"))));
};
TeamMemberStatusSection.propTypes = {};
export default TeamMemberStatusSection;
