import React from 'react';
import { Modal } from '@forecast-it/design-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
const DeletePhaseModal = ({ closeModal, callback, hasEstimates }) => {
    const deletePhase = () => {
        callback();
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: 'phase_management.delete_phase_title' })),
        React.createElement(Modal.Content, null,
            React.createElement(FormattedHTMLMessage, { id: 'phase_management.delete_phase_check' }),
            hasEstimates ? (React.createElement(FormattedHTMLMessage, { id: 'phase_management.delete_phase_with_baseline' })) : (React.createElement(FormattedHTMLMessage, { id: 'phase_management.delete_phase_without_baseline' }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: deletePhase, dataCy: 'modal-btn-confirm-delete', disabled: false },
                React.createElement(FormattedMessage, { id: "phase_management.delete_phase" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: "common.cancel" })))));
};
export default DeletePhaseModal;
