import React from 'react';
import Styled from 'styled-components/macro';
const BackgroundStyle = Styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 12px;
	
	width: 40px;
	height: fit-content;
`;
const RowContainer = Styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 4px;
	
	width: 40px;
	height: 40px;
`;
const IndexColumn = Styled.div `
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px;
	gap: 12px;	
	width: 40px;
	height: 40px;
	background: #F7F7FE;
	border-radius: 4px;
`;
const PriorityIndex = Styled.div `
	width: 7px;
	height: 16px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	
	text-align: center;
	letter-spacing: 0.2px;
	
	color: ${props => (props.disabled ? '#393946' : '#1F9CFF')};
`;
export const Background = ({ rows, disabled }) => {
    return (React.createElement(BackgroundStyle, null, Array.from({ length: rows }).map((_, index) => (React.createElement(RowContainer, { key: index },
        React.createElement(IndexColumn, null,
            React.createElement(PriorityIndex, { disabled: disabled }, index + 1)))))));
};
