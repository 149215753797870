var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ClipboardButton from 'react-clipboard.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from 'web-components';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import { LabelDropdown } from '../../components/dropdowns/LabelDropdown/LabelDropdown';
import InputField from '../../components/inputs/input_field';
import SelectInput from '../../components/inputs/select_input';
import Warning from '../../components/warning';
import { BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, INITIALS_SIZE, MODULE_TYPES } from '../../constants';
import DirectApi, { profilePicSrc } from '../../directApi';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import { TopHeaderBar, TopHeaderBarWrapper } from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Person from '../../forecast-app/shared/components/person/person';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { createToast } from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import { hasFeatureFlag } from '../../forecast-app/shared/util/FeatureUtil';
import { hasModule } from '../../forecast-app/shared/util/ModuleUtil';
import { getProfileName, getSelectableProfiles, hasPermission, personIsAdmin, personIsVirtualUser, } from '../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../forecast-app/shared/util/util';
import HarvestIcon from '../../images/integrations/harvest-logo.png';
import DeleteSisenseUserMutation from '../../mutations/DeleteSisenseUserMutation';
import SetBambooHRUserMutation from '../../mutations/SetBambooHRUserMutation';
import CreateSlackUserVerificationKeyMutation from '../../mutations/slack_integration/CreateSlackUserVerificationKeyMutation';
import SetSlackUserMutation from '../../mutations/slack_integration/SetSlackUserMutation';
import UpdatePersonMutation from '../../mutations/update_person_mutation.modern';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import { PERMISSION_TYPE } from '../../Permissions';
import { versionManager } from '../../version_manager';
import { HiBobIntegration } from './integrations/HiBobIntegration';
import TeamMemberStatusSection from './people/TeamMemberStatusSection';
import SettingsCalendarWrapper from './settingsCalendar/SettingsCalendarWrapper';
import SettingsProfileSubNav from './SettingsProfileSubNav';
import ProfileSkills from './skills/ProfileSkills';
import { UnlinkButton } from './UnlinkButton';
function getWorkingHours(language, person) {
    return language === 'ENGLISH_US'
        ? [
            person.sunday / 60,
            person.monday / 60,
            person.tuesday / 60,
            person.wednesday / 60,
            person.thursday / 60,
            person.friday / 60,
            person.saturday / 60,
        ]
        : [
            person.monday / 60,
            person.tuesday / 60,
            person.wednesday / 60,
            person.thursday / 60,
            person.friday / 60,
            person.saturday / 60,
            person.sunday / 60,
        ];
}
const TopHeader = ({ viewUser, person }) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    return (React.createElement(TopHeaderBarWrapper, null,
        React.createElement(TopHeaderBar, { title: viewUser
                ? person.client
                    ? formatMessage({ id: 'settings_profile.client-user-profile' })
                    : formatMessage({ id: 'settings_profile.team-member-profile' })
                : 'Account Configuration', content: viewUser ? [{ type: TopHeaderBar.TYPE.BACK_BUTTON, history }] : [] })));
};
export const SettingsProfile = (_a) => {
    var _b, _c, _d, _e, _f, _g, _h;
    var { person, viewer, company, isProfileUpdating, language, viewUser, changeName, changeHolidayCalendar, changeJobTitle, changeEmail, changePhone, changeProfile, changeRole, changeDepartment, changeLanguage, changeProfilePicture, deleteProfilePicture, isViewer } = _a, props = __rest(_a, ["person", "viewer", "company", "isProfileUpdating", "language", "viewUser", "changeName", "changeHolidayCalendar", "changeJobTitle", "changeEmail", "changePhone", "changeProfile", "changeRole", "changeDepartment", "changeLanguage", "changeProfilePicture", "deleteProfilePicture", "isViewer"]);
    useEffect(() => {
        document.title = 'Details - My Profile - Forecast';
    }, []);
    const fileInputRef = useRef(null);
    const { formatMessage } = useIntl();
    const [name, setName] = useState([person.firstName, person.lastName].filter(Boolean).join(' '));
    const [phone, setPhone] = useState((_b = person.phone) !== null && _b !== void 0 ? _b : '');
    const [jobTitle, setJobTitle] = useState((_c = person.jobTitle) !== null && _c !== void 0 ? _c : '');
    const [selectedLabels, setSelectedLabels] = useState(person.personLabels.edges.map(personLabel => personLabel.node.label.id));
    const [email, setEmail] = useState((_d = person.email) !== null && _d !== void 0 ? _d : '');
    useEffect(() => {
        setEmail(person.email);
    }, [person.email]);
    const [workingHours, setWorkingHours] = useState(getWorkingHours(language, person));
    useEffect(() => {
        setWorkingHours(getWorkingHours(language, person));
    }, [language, person]);
    const [showEmailValidationError, setShowEmailValidationError] = useState(false);
    useEffect(() => {
        setShowEmailValidationError(props.showEmailValidationError);
    }, [props.showEmailValidationError, props.emailValidationErrorMessage]);
    const [gitlabLoading, setGitlabLoading] = useState(false);
    const [gitlabServerLoading, setGitlabServerLoading] = useState(false);
    const [gitlabUsers, setGitlabUsers] = useState([]);
    const [gitlabServerUsers, setGitlabServerUsers] = useState([]);
    const [gitlabSearchString, setGitlabSearchString] = useState('');
    const [gitlabServerSearchString, setGitlabServerSearchString] = useState('');
    const [linkedGitlabUser, setLinkedGitlabUser] = useState(null);
    const [linkedGitlabServerUser, setLinkedGitlabServerUser] = useState(null);
    const [githubLoading, setGithubLoading] = useState(false);
    const [githubUsers, setGithubUsers] = useState([]);
    const [githubSearchString, setGithubSearchString] = useState('');
    const [linkedGithubUser, setLinkedGithubUser] = useState(null);
    const [harvestLoading, setHarvestLoading] = useState(false);
    const [harvestUsersName, setHarvestUsersName] = useState('');
    const [unit4Loading, setUnit4Loading] = useState(false);
    const [unit4Users, setUnit4Users] = useState([]);
    const [unit4UserOptions, setUnit4UserOptions] = useState([]);
    const [pendingUnit4Query, setPendingUnit4Query] = useState('');
    const [bambooHRUsers, setBambooHRUsers] = useState([]);
    const [bambooHRLoading, setBambooHRLoading] = useState(false);
    const [slackUsers, setSlackUsers] = useState([]);
    const [slackLoading, setSlackLoading] = useState(false);
    const [jiraCloudLoading, setJiraCloudLoading] = useState(false);
    const [jiraServerLoading, setJiraServerLoading] = useState(false);
    const [jiraCloudUsers, setJiraCloudUsers] = useState([]);
    const [jiraServerUsers, setJiraServerUsers] = useState([]);
    const [sageIntacctUsers, setSageIntacctUsers] = useState([]);
    const [sageIntacctLoading, setSageIntacctLoading] = useState(false);
    const showChangesSavedToast = () => {
        createToast({ duration: 2000, message: formatMessage({ id: 'common.changes_saved' }) });
    };
    const handleBlurName = () => {
        const [first, ...rest] = name.trim().split(' ');
        changeName(first.trim(), rest.join(' ').trim());
    };
    const handleChangeProfilePicture = () => {
        const fileInput = fileInputRef.current;
        if ((fileInput === null || fileInput === void 0 ? void 0 : fileInput.files) &&
            fileInput.files[0] !== undefined &&
            fileInput.files[0] !== null &&
            (fileInput.files[0].type === 'image/jpeg' ||
                fileInput.files[0].type === 'image/gif' ||
                fileInput.files[0].type === 'image/png')) {
            changeProfilePicture(fileInput.files[0]);
        }
        else {
            showModal({
                type: MODAL_TYPE.GENERIC,
                content: (React.createElement("div", { className: "default-warning-modal" },
                    React.createElement(Warning, { messageId: "warning_modal.profile_picture_wrong_format_title" }),
                    React.createElement("div", { className: "warning-part-2" }, formatMessage({ id: 'warning_modal.profile_picture_wrong_format_info' })))),
                buttons: [
                    {
                        text: formatMessage({ id: 'common.cancel' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                ],
            });
        }
    };
    const updateLabels = updatedLabels => {
        setSelectedLabels(updatedLabels);
        if (isViewer) {
            Util.CommitMutation(UpdateViewerMutation, {
                labels: updatedLabels,
            }, () => showChangesSavedToast());
        }
        else {
            Util.CommitMutation(UpdatePersonMutation, {
                id: person.id,
                labels: updatedLabels,
            }, () => showChangesSavedToast());
        }
    };
    const saveWorkingHours = (i, e) => {
        const newHours = workingHours.slice();
        if (newHours[i] === e)
            return; //Don't trigger if the value did not change
        newHours[i] = e;
        const validateHours = val => {
            if (isNaN(val))
                return false;
            if (parseFloat(val) < -600000)
                return false;
            else if (parseFloat(val) > 600000)
                return false;
            return true;
        };
        let monday, tuesday, wednesday, thursday, friday, saturday, sunday;
        if (viewer.language === 'ENGLISH_US') {
            sunday = newHours[0];
            monday = newHours[1];
            tuesday = newHours[2];
            wednesday = newHours[3];
            thursday = newHours[4];
            friday = newHours[5];
            saturday = newHours[6];
        }
        else {
            monday = newHours[0];
            tuesday = newHours[1];
            wednesday = newHours[2];
            thursday = newHours[3];
            friday = newHours[4];
            saturday = newHours[5];
            sunday = newHours[6];
        }
        let isValid = true;
        [monday, tuesday, wednesday, thursday, friday, saturday, sunday].forEach(val => {
            if (!validateHours(val)) {
                isValid = false;
            }
        });
        const working_hours = [monday, tuesday, wednesday, thursday, friday, saturday];
        if (language === 'ENGLISH_US') {
            working_hours.splice(0, 0, sunday);
        }
        else {
            working_hours.push(sunday);
        }
        setWorkingHours(working_hours);
        if (!isValid)
            return;
        if (viewUser) {
            Util.CommitMutation(UpdatePersonMutation, {
                id: person.id,
                monday: Math.round(monday * 60),
                tuesday: Math.round(tuesday * 60),
                wednesday: Math.round(wednesday * 60),
                thursday: Math.round(thursday * 60),
                friday: Math.round(friday * 60),
                saturday: Math.round(saturday * 60),
                sunday: Math.round(sunday * 60),
            }, () => showChangesSavedToast());
        }
        else {
            Util.CommitMutation(UpdateViewerMutation, {
                monday: Math.round(monday * 60),
                tuesday: Math.round(tuesday * 60),
                wednesday: Math.round(wednesday * 60),
                thursday: Math.round(thursday * 60),
                friday: Math.round(friday * 60),
                saturday: Math.round(saturday * 60),
                sunday: Math.round(sunday * 60),
            }, () => showChangesSavedToast());
        }
        setWorkingHours(newHours);
    };
    const fetchGitLabUsers = server => {
        setGitlabLoading(true);
        DirectApi.Fetch(`gitlab_users?server=${server}&searchString=${server ? gitlabServerSearchString : gitlabSearchString}`)
            .then(json => {
            if (server) {
                setGitlabServerLoading(false);
                setGitlabServerUsers(json || []);
            }
            else {
                setGitlabLoading(false);
                setGitlabUsers(json || []);
            }
        })
            .catch(() => {
            setGitlabServerLoading(false);
            setGitlabLoading(false);
        });
    };
    const fetchUnit4Resources = filterString => {
        if (company.unit4Enabled) {
            if (unit4Loading) {
                // Users already being fetched, store query for later
                setPendingUnit4Query(filterString);
            }
            else {
                setUnit4Loading(true);
                setPendingUnit4Query('');
                DirectApi.Fetch(`unit4_users?filterString=${filterString}`).then(users => {
                    const options = users
                        .filter(u => !u.forecastId)
                        .sort((a, b) => {
                        const userA = a.firstName.toUpperCase().trim() + ' ' + a.surname.toUpperCase().trim();
                        const userB = b.firstName.toUpperCase().trim() + ' ' + b.surname.toUpperCase().trim();
                        if (userA > userB) {
                            return 1;
                        }
                        else if (userA < userB) {
                            return -1;
                        }
                        return 0;
                    })
                        .map(u => ({ value: u.personId, label: u.firstName.trim() + ' ' + u.surname.trim() }));
                    setUnit4Users(users);
                    setUnit4UserOptions(options);
                    setUnit4Loading(false);
                    if (pendingUnit4Query) {
                        // Query updated while fetching, fetch again with updated query.
                        fetchUnit4Resources(pendingUnit4Query);
                    }
                });
            }
        }
    };
    const fetchBambooHRUsers = () => {
        var _a;
        if (company.bambooHREnabled) {
            setBambooHRLoading(true);
            const personId = (_a = person.actualPersonId) !== null && _a !== void 0 ? _a : person.id;
            DirectApi.Fetch(`bamboohr/employees?personId=${Util.getIdFromBase64String(personId)}`).then(users => {
                setBambooHRUsers(users
                    .map(u => ({ value: u.id, label: `${u.firstName.trim()} ${u.lastName.trim()} (${u.workEmail})` }))
                    .sort((u1, u2) => (u1.label < u2.label ? -1 : 1)));
                setBambooHRLoading(false);
            });
        }
    };
    const fetchSlackUsers = () => {
        var _a;
        if (company.newSlackEnabled && person.slackId) {
            setSlackLoading(true);
            const personId = (_a = person.actualPersonId) !== null && _a !== void 0 ? _a : person.id;
            DirectApi.Fetch(`slack/users/${Util.getIdFromBase64String(personId)}`).then(users => {
                setSlackUsers(users
                    .map(u => ({
                    value: u.id,
                    label: `${u.profile.first_name.trim()} ${u.profile.last_name.trim()}`,
                }))
                    .sort((u1, u2) => (u1.label < u2.label ? -1 : 1)));
                setSlackLoading(false);
            });
        }
    };
    const fetchSageIntacctUsers = () => {
        if (company.sageIntacctConnectionEnabled && !person.sageIntacctId) {
            setSageIntacctLoading(true);
            DirectApi.Fetch('sage_intacct/employees').then(res => {
                if (res === null || res === void 0 ? void 0 : res.employees) {
                    setSageIntacctUsers(res.employees
                        .map(u => ({
                        value: u.EMPLOYEEID,
                        label: `${u.PERSONALINFO__2e__FIRSTNAME} ${u.PERSONALINFO__2e__LASTNAME} (${u.PERSONALINFO__2e__EMAIL1})`,
                        name: `${u.PERSONALINFO__2e__FIRSTNAME} ${u.PERSONALINFO__2e__LASTNAME}`,
                        email: u.PERSONALINFO__2e__EMAIL1,
                        location: u.LOCATIONID,
                    }))
                        .sort((a, b) => (a.label.toUpperCase().trim() > b.label.toUpperCase().trim() ? 1 : -1)));
                    setSageIntacctLoading(false);
                }
                else {
                    setSageIntacctUsers([]);
                    setSageIntacctLoading(false);
                }
            });
        }
    };
    const fetchHarvestUser = () => {
        if (company.harvestEnabled && person.harvestUser) {
            setHarvestLoading(true);
            let param;
            if (isViewer) {
                param = person.actualPersonId;
            }
            else {
                param = person.id;
            }
            DirectApi.Fetch(`harvest_users?personId=${param}`).then(json => {
                setHarvestUsersName(json.user);
                setHarvestLoading(false);
            });
        }
        else if (company.harvestEnabled) {
            setHarvestUsersName('User is not linked to a Harvest user');
        }
    };
    const fetchJiraCloudUsers = () => {
        if (company.jiraCloudEnabled) {
            setJiraCloudLoading(true);
            DirectApi.Fetch(`jira/cloud/users/active`)
                .then(json => {
                setJiraCloudLoading(false);
                setJiraCloudUsers(json.map(user => ({
                    linked: user.linked,
                    value: user.accountId,
                    label: `${user.displayName} (${user.emailAddress ? user.emailAddress : 'No Email'})`,
                })) || []);
            })
                .catch(() => {
                setJiraCloudLoading(false);
                setJiraCloudUsers([]);
            });
        }
    };
    const fetchJiraServerUsers = () => {
        if (company.jiraServerEnabled) {
            setJiraServerLoading(true);
            DirectApi.Fetch(`jira/server/users/active`)
                .then(json => {
                setJiraServerLoading(false);
                setJiraServerUsers(json.map(user => ({
                    linked: user.linked,
                    value: user.key,
                    label: `${user.displayName} (${user.emailAddress ? user.emailAddress : 'No Email'})`,
                })) || []);
            })
                .catch(() => {
                setJiraServerLoading(false);
                setJiraServerUsers([]);
            });
        }
    };
    const fetchGitLabUser = () => {
        if (company.gitlabEnabled && person.gitlabUser) {
            const id = person.gitlabUser.integrationUserId;
            if (id) {
                DirectApi.Fetch(`gitlab_users?server=false&id=${id}`).then(json => {
                    setLinkedGitlabUser(json);
                });
            }
        }
        if (company.gitlabServerEnabled && person.gitlabServerUser) {
            const id = person.gitlabServerUser.integrationUserId;
            if (id) {
                DirectApi.Fetch(`gitlab_users?server=true&id=${id}`).then(json => {
                    setLinkedGitlabServerUser(json);
                });
            }
        }
    };
    const updateGitlabUser = (userId, server, onSuccess) => {
        if (viewUser) {
            const object = {
                id: person.id,
                gitlabPersonId: server ? undefined : userId,
                gitlabServerPersonId: server ? userId : undefined,
            };
            Util.CommitMutation(UpdatePersonMutation, object, onSuccess);
        }
        else {
            Util.CommitMutation(UpdateViewerMutation, {
                gitlabPersonId: server ? undefined : userId,
                gitlabServerPersonId: server ? userId : undefined,
            }, onSuccess);
        }
    };
    const selectGitlabUser = (u, server) => {
        const imageSrc = profilePicSrc(person.profilePictureId);
        const onSuccess = () => {
            if (server) {
                setLinkedGitlabServerUser(u);
            }
            else {
                setLinkedGitlabUser(u);
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.link_users',
            warningInformation: [
                React.createElement("div", { className: "gitlab-modal-content" },
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.integration_user_link" })),
                    React.createElement(Person, { name: name, showName: true, role: email, showRole: true, imageSrc: imageSrc, imageSize: "medium" }),
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.gitlab_link2" })),
                    React.createElement("div", { className: "gitlab-user" },
                        React.createElement("img", { height: "24", width: "24", src: u.avatar_url }),
                        React.createElement("div", { className: "name" },
                            React.createElement("span", null, u.name),
                            React.createElement("span", { className: "username" }, '@' + u.username)))),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.link' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: () => updateGitlabUser(u.id, server, onSuccess),
                },
            ],
        });
    };
    const removeGitLabUser = server => {
        const onSuccess = () => {
            if (server) {
                setLinkedGitlabServerUser(null);
            }
            else {
                setLinkedGitlabUser(null);
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.gitlab_unlink',
            warningInformation: [formatMessage({ id: 'settings_profile.gitlab_unlink_modal_description' })],
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: () => updateGitlabUser(0, server, onSuccess),
                },
            ],
        });
    };
    const fetchGithubUsers = () => {
        if (company.githubEnabled) {
            setGithubLoading(true);
            DirectApi.Fetch(`github_users?searchString=${githubSearchString}`)
                .then(json => {
                setGithubLoading(false);
                setGithubUsers(json || []);
            })
                .catch(() => {
                setGithubLoading(false);
                setGithubUsers([]);
            });
        }
    };
    const fetchGithubUser = () => {
        if (company.githubEnabled && person.githubUser) {
            const id = person.githubUser.integrationUserId;
            if (id) {
                DirectApi.Fetch(`github_users?&id=${id}`)
                    .then(json => {
                    setLinkedGithubUser(json);
                })
                    .catch(() => {
                    setLinkedGithubUser(null);
                });
            }
        }
    };
    const updateGithubUser = (userId, onSuccess) => {
        if (viewUser) {
            const object = {
                id: person.id,
                githubPersonId: userId,
            };
            Util.CommitMutation(UpdatePersonMutation, object, onSuccess);
        }
        else {
            Util.CommitMutation(UpdateViewerMutation, {
                githubPersonId: userId,
            }, onSuccess);
        }
    };
    const selectGithubUser = (u) => {
        const imageSrc = profilePicSrc(person.profilePictureId);
        const onSuccess = () => {
            setLinkedGithubUser(u);
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.link_users',
            warningInformation: [
                React.createElement("div", { className: "github-modal-content" },
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.integration_user_link" })),
                    React.createElement(Person, { name: name, showName: true, role: email, showRole: true, imageSrc: imageSrc, imageSize: "medium" }),
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.github_link2" })),
                    React.createElement("div", { className: "github-user" },
                        React.createElement("img", { height: "24", width: "24", src: u.avatar_url, alt: '' }),
                        React.createElement("div", { className: "name" },
                            React.createElement("span", null, u.username),
                            React.createElement("span", { className: "username" }, '@' + u.username)))),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.link' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: () => updateGithubUser(u.id, onSuccess),
                },
            ],
        });
    };
    const removeGithubUser = () => {
        const onSuccess = () => {
            setLinkedGithubUser(null);
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.github_unlink',
            warningInformation: [formatMessage({ id: 'settings_profile.github_unlink_modal_description' })],
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: () => updateGithubUser(0, onSuccess),
                },
            ],
        });
    };
    const unlinkUnit4User = () => {
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings_profile.unit4_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        if (viewUser) {
                            Util.CommitMutation(UpdatePersonMutation, {
                                id: person.id,
                                unit4PersonId: null,
                            });
                        }
                        else {
                            Util.CommitMutation(UpdateViewerMutation, {
                                unit4PersonId: null,
                            });
                        }
                    },
                },
            ],
        });
    };
    const linkUnit4User = option => {
        const imageSrc = profilePicSrc(person.profilePictureId);
        const callback = () => {
            if (option) {
                if (viewUser) {
                    Util.CommitMutation(UpdatePersonMutation, {
                        id: person.id,
                        unit4PersonId: option.value,
                    });
                }
                else {
                    Util.CommitMutation(UpdateViewerMutation, {
                        unit4PersonId: option.value,
                    });
                }
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.link_users',
            warningInformation: [
                React.createElement("div", { className: "unit4-modal-content" },
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.integration_user_link" })),
                    React.createElement(Person, { name: name, showName: true, role: email, showRole: true, imageSrc: imageSrc, imageSize: "medium" }),
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.unit4_link" })),
                    React.createElement("div", { className: "unit4-user" }, option.label)),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const setBambooHRUser = (input) => {
        var _a;
        const personId = (_a = person.actualPersonId) !== null && _a !== void 0 ? _a : person.id;
        if (input) {
            // Linking
            Util.CommitMutation(SetBambooHRUserMutation, { forecastPersonId: personId, bambooHRUserId: input.value });
        }
        else {
            // Unlinking
            showModal({
                type: MODAL_TYPE.GENERIC,
                className: 'default-warning-modal',
                content: React.createElement(Warning, { messageId: "settings_profile.bamboohr_unlink" }),
                buttons: [
                    {
                        text: formatMessage({ id: 'common.cancel' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                    {
                        text: formatMessage({ id: 'common.unlink' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.RED,
                        callback: () => Util.CommitMutation(SetBambooHRUserMutation, { forecastPersonId: personId, bambooHRUserId: null }),
                    },
                ],
            });
        }
    };
    const setSageIntacctUser = (input) => {
        const data = {
            sageIntacctId: (input === null || input === void 0 ? void 0 : input.value) ? input === null || input === void 0 ? void 0 : input.value : null,
            sageIntacctName: (input === null || input === void 0 ? void 0 : input.name) ? input === null || input === void 0 ? void 0 : input.name : null,
            sageIntacctEmail: (input === null || input === void 0 ? void 0 : input.email) ? input === null || input === void 0 ? void 0 : input.email : null,
            sageIntacctLocationId: (input === null || input === void 0 ? void 0 : input.location) ? input === null || input === void 0 ? void 0 : input.location : null,
        };
        if (viewUser) {
            data.id = person.id;
        }
        const mutation = viewUser ? UpdatePersonMutation : UpdateViewerMutation;
        if (input) {
            Util.CommitMutation(mutation, data, null, true);
        }
        else {
            showModal({
                type: MODAL_TYPE.GENERIC,
                className: 'default-warning-modal',
                content: React.createElement(Warning, { message: "Are you sure you want to unlink this user from Sage Intacct?" }),
                buttons: [
                    {
                        text: formatMessage({ id: 'common.cancel' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                    {
                        text: formatMessage({ id: 'common.unlink' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.RED,
                        callback: () => Util.CommitMutation(mutation, data, null, true),
                    },
                ],
            });
        }
    };
    const unlinkSlackUser = () => {
        var _a;
        const personId = (_a = person.actualPersonId) !== null && _a !== void 0 ? _a : person.id;
        // Unlinking
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings_profile.slack_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => Util.CommitMutation(SetSlackUserMutation, { personId: personId, slackId: null }),
                },
            ],
        });
    };
    const linkSlackUser = () => {
        const redirectUri = `${process.env.API_URL}/slack/oauth_user`;
        const onSuccess = ({ createSlackUserVerificationKey }) => {
            const scopes = ['channels:read', 'groups:read'];
            window.location.href = `https://slack.com/oauth/v2/authorize?client_id=${process.env.SLACK_APP_CLIENT_ID}&redirect_uri=${redirectUri}&scope=&user_scope=${scopes.join(',')}&state=${createSlackUserVerificationKey.key}`;
        };
        Util.CommitMutation(CreateSlackUserVerificationKeyMutation, null, onSuccess);
    };
    const unlinkJiraUser = type => {
        showModal({
            type: MODAL_TYPE.GENERIC,
            className: 'default-warning-modal',
            content: React.createElement(Warning, { messageId: "settings_profile.jira_unlink" }),
            buttons: [
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        const data = type === 'cloud' ? { jiraCloudId: null } : { jiraServerId: null };
                        if (viewUser) {
                            data.id = person.id;
                            Util.CommitMutation(UpdatePersonMutation, data);
                        }
                        else {
                            Util.CommitMutation(UpdateViewerMutation, data);
                        }
                    },
                },
            ],
        });
    };
    const linkJiraUser = (option, type) => {
        const imageSrc = profilePicSrc(person.profilePictureId);
        const callback = () => {
            if (option) {
                const data = type === 'cloud' ? { jiraCloudId: option.value } : { jiraServerId: option.value };
                if (viewUser) {
                    data.id = person.id;
                    Util.CommitMutation(UpdatePersonMutation, data);
                }
                else {
                    Util.CommitMutation(UpdateViewerMutation, data);
                }
            }
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'settings_profile.link_users',
            warningInformation: [
                React.createElement("div", { className: "jira-modal-content" },
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.integration_user_link" })),
                    React.createElement(Person, { name: name, showName: true, role: email, showRole: true, imageSrc: imageSrc, imageSize: "medium" }),
                    React.createElement("div", null,
                        React.createElement(FormattedMessage, { id: "settings_profile.jira_link" })),
                    React.createElement("div", { className: "jira-user" }, option.label)),
            ],
            useInfoIcon: true,
            buttons: [
                {
                    text: React.createElement(FormattedMessage, { id: "common.cancel" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
                {
                    text: React.createElement(FormattedMessage, { id: "common.link" }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.GREEN,
                    callback: callback,
                },
            ],
        });
    };
    const updateStartDate = (startDate) => {
        const onSuccess = () => {
            showChangesSavedToast();
        };
        const startDateValue = startDate && startDate.format('YYYY-MM-DD');
        if (isViewer) {
            Util.CommitMutation(UpdateViewerMutation, {
                startDate: startDateValue,
            }, onSuccess);
        }
        else {
            Util.CommitMutation(UpdatePersonMutation, {
                id: person.id,
                startDate: startDateValue,
            }, onSuccess);
        }
    };
    const updateEndDate = (endDate) => {
        const onSuccess = () => {
            showChangesSavedToast();
        };
        const endDateValue = endDate && endDate.format('YYYY-MM-DD');
        if (isViewer) {
            Util.CommitMutation(UpdateViewerMutation, {
                endDate: endDateValue,
            }, onSuccess);
        }
        else {
            Util.CommitMutation(UpdatePersonMutation, {
                id: person.id,
                endDate: endDateValue,
            }, onSuccess);
        }
    };
    const deleteSisenseUser = () => {
        const callbackPositive = () => {
            var _a;
            const onSuccess = res => {
                const { errors } = res.deleteSisenseUser;
                if (errors === null || errors === void 0 ? void 0 : errors.length) {
                    if (errors[0] === 'PERSON_OWNS_DASHBOARDS') {
                        showModal({
                            type: MODAL_TYPE.WARNING,
                            warningMessageId: 'sisense.person_owns_dashboards.title',
                            warningInformation: [React.createElement(FormattedMessage, { id: "sisense.person_owns_dashboards.message" })],
                            useInfoIcon: true,
                            buttons: [
                                {
                                    text: formatMessage({ id: 'common.ok' }),
                                    style: BUTTON_STYLE.FILLED,
                                    color: BUTTON_COLOR.WHITE,
                                },
                            ],
                        });
                    }
                    else {
                        createToast({ duration: 2000, message: 'Error deleting Advanced Analytics user' });
                    }
                }
                else {
                    createToast({ duration: 2000, message: 'Advanced Analytics user deleted successfully' });
                }
            };
            Util.CommitMutation(DeleteSisenseUserMutation, {
                personId: (_a = person.actualPersonId) !== null && _a !== void 0 ? _a : person.id,
            }, onSuccess);
        };
        showModal({
            type: MODAL_TYPE.WARNING,
            warningMessageId: 'warning-modal.edit-warning-title',
            warningInformation: [React.createElement(FormattedMessage, { id: "sisense.delete_user.message" })],
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.delete' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: callbackPositive,
                },
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
            ],
        });
    };
    const linkAdoUser = () => {
        showModal({
            type: MODAL_TYPE.ADO_LINK_USER,
            isViewer: isViewer,
            personId: person.id,
        });
    };
    const unlinkAdoUser = () => {
        showModal({
            type: MODAL_TYPE.WARNING,
            message: `Are you sure you want to unlink ${person.firstName}${person.lastName ? ` ${person.lastName}` : ''} from Azure DevOps?`,
            useInfoIcon: true,
            buttons: [
                {
                    text: formatMessage({ id: 'common.unlink' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.RED,
                    callback: () => {
                        const onSuccess = () => {
                            createToast({ duration: 2000, message: 'User unlinked from Azure DevOps' });
                        };
                        if (isViewer) {
                            Util.CommitMutation(UpdateViewerMutation, {
                                adoUserId: '',
                            }, onSuccess);
                        }
                        else {
                            Util.CommitMutation(UpdatePersonMutation, {
                                id: person.id,
                                adoUserId: '',
                            }, onSuccess);
                        }
                    },
                },
                {
                    text: formatMessage({ id: 'common.cancel' }),
                    style: BUTTON_STYLE.FILLED,
                    color: BUTTON_COLOR.WHITE,
                },
            ],
        });
    };
    useEffect(() => {
        // If oauth is true "click" the link slack user button
        if (props.oauthSlack)
            linkSlackUser();
        fetchHarvestUser();
        fetchGitLabUser();
        fetchGithubUser();
        fetchJiraCloudUsers();
        fetchJiraServerUsers();
        fetchBambooHRUsers();
        fetchSlackUsers();
        fetchSageIntacctUsers();
    }, []);
    const allRoles = company.roles.edges.map(edge => ({ value: edge.node.id, label: edge.node.name }));
    const departments = company.departments.edges.map(edge => ({ value: edge.node.id, label: edge.node.name }));
    const languages = [
        { value: 'DANISH', label: 'Dansk (DK)' },
        { value: 'ENGLISH_US', label: 'English (US)' },
        { value: 'ENGLISH_UK', label: 'English (UK)' },
        { value: 'ENGLISH_EU', label: 'English (EU)' },
        { value: 'FRENCH', label: 'Français (FR)' },
    ];
    if (person.language === 'SPANISH') {
        languages.push({ value: 'SPANISH', label: 'Español (ES)' });
    }
    const weekdays = language === 'ENGLISH_US'
        ? [
            formatMessage({ id: 'common.sunday' }),
            formatMessage({ id: 'common.monday' }),
            formatMessage({ id: 'common.tuesday' }),
            formatMessage({ id: 'common.wednesday' }),
            formatMessage({ id: 'common.thursday' }),
            formatMessage({ id: 'common.friday' }),
            formatMessage({ id: 'common.saturday' }),
        ]
        : [
            formatMessage({ id: 'common.monday' }),
            formatMessage({ id: 'common.tuesday' }),
            formatMessage({ id: 'common.wednesday' }),
            formatMessage({ id: 'common.thursday' }),
            formatMessage({ id: 'common.friday' }),
            formatMessage({ id: 'common.saturday' }),
            formatMessage({ id: 'common.sunday' }),
        ];
    const holidayCalendarOptions = [{ value: null, label: formatMessage({ id: 'settings.rate_card_default' }) }].concat(company.holidayCalendars.edges.map(holidayCalendarEdge => {
        return { value: holidayCalendarEdge.node.id, label: holidayCalendarEdge.node.name };
    }));
    const forecast_user = localStorage.getItem('forecast_user');
    const webFrontVersion = versionManager.getClientMajorVersion() + '.' + versionManager.getClientMinorVersion();
    const webFrontVersionTime = moment(versionManager.getClientVersionTime());
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    let unit4Username = '';
    if (person.unit4UserObject && unit4Users) {
        const unit4User = unit4Users.find(u => parseInt(u.personId) === person.unit4UserObject);
        unit4Username = unit4User
            ? unit4User.firstName.trim() + ' ' + unit4User.surname.trim()
            : person.unit4UserObject;
    }
    let jiraCloudUsername = '';
    if (!jiraCloudLoading && jiraCloudUsers) {
        const jiraUser = jiraCloudUsers.find(u => u.value === person.jiraCloudId);
        if (jiraUser)
            jiraCloudUsername = jiraUser.label;
    }
    let jiraServerUsername = '';
    if (!jiraServerLoading && jiraServerUsers) {
        const jiraUser = jiraServerUsers.find(u => u.value === person.jiraServerId);
        if (jiraUser)
            jiraServerUsername = jiraUser.label;
    }
    let bambooHRUsername = '';
    if (!bambooHRLoading && bambooHRUsers) {
        const bambooUser = bambooHRUsers.find(u => u.value === person.bambooHRId);
        if (bambooUser)
            bambooHRUsername = bambooUser.label;
    }
    let slackUsername = '';
    if (!slackLoading && slackUsers) {
        const slackUser = slackUsers.find(u => u.value === person.slackId);
        if (slackUser)
            slackUsername = slackUser.label;
    }
    const labels = company.labels.edges.map(edge => edge.node);
    return (React.createElement("div", { className: "section-content settings-app settings-profile", "data-cy": 'profile-page' },
        props.children,
        React.createElement(TopHeader, { person: person, viewUser: viewUser }),
        isViewer && React.createElement(SettingsProfileSubNav, { viewer: viewer }),
        React.createElement(CustomScrollDiv, null,
            React.createElement("div", { className: "section-body" },
                React.createElement("div", { className: "inner" },
                    React.createElement("fieldset", { className: "personal-info" },
                        React.createElement("div", { className: "info-header" },
                            React.createElement("h3", null, viewUser
                                ? person.client
                                    ? formatMessage({ id: 'settings_profile.client_user_profile_info' })
                                    : formatMessage({ id: 'settings_profile.team-member-info' })
                                : formatMessage({ id: 'settings_profile.personal-info' }))),
                        React.createElement("p", { className: "description" }, viewUser ? (person.client ? (React.createElement(FormattedMessage, { id: "settings_profile.client_user_info" })) : (React.createElement(FormattedMessage, { id: "settings.team_member_info" }))) : (React.createElement(FormattedMessage, { id: "settings.profile_info" }))),
                        React.createElement("div", { className: "titles-material" },
                            React.createElement(InputField, { cy: 'member-name', label: formatMessage({ id: 'common.name' }), type: "text", placeholder: viewUser
                                    ? person.client
                                        ? formatMessage({ id: 'settings_profile.enter_client_user_name' })
                                        : formatMessage({ id: 'settings_profile.enter-team-member-name' })
                                    : formatMessage({ id: 'settings_profile.enter-your-name' }), value: name, onBlur: handleBlurName, onChange: setName, blurOnEnter: true }),
                            person.client ? (React.createElement(InputField, { label: formatMessage({ id: 'common.phone' }), type: "text", placeholder: formatMessage({ id: 'project-client.contact-phone-input-placeholder' }), value: phone, onChange: value => {
                                    setPhone(value);
                                }, onBlur: () => {
                                    changePhone(phone);
                                }, blurOnEnter: true, maxLength: 45 })) : null,
                            React.createElement(InputField, { cy: 'member-email', label: formatMessage({ id: 'common.email' }), locked: person.demoUser, type: "email", placeholder: viewUser
                                    ? person.demoUser
                                        ? formatMessage({ id: 'settings_profile.demo_user_no_email' })
                                        : person.client
                                            ? formatMessage({ id: 'settings_profile.enter_client_user_email' })
                                            : formatMessage({ id: 'settings_profile.enter-team-member-email' })
                                    : formatMessage({ id: 'settings_profile.enter-your-email' }), id: "email_address", value: email, onChange: value => {
                                    setEmail(value);
                                }, onBlur: valid => {
                                    if (valid) {
                                        changeEmail(email);
                                    }
                                }, checkEmailValidity: isValid => {
                                    setShowEmailValidationError(!isValid);
                                }, errorMessage: props.emailValidationErrorMessage && props.emailValidationErrorMessage !== ''
                                    ? props.emailValidationErrorMessage
                                    : formatMessage({ id: 'settings_profile.invalid_email' }), inputValid: !showEmailValidationError, blurOnEnter: true }),
                            React.createElement(InputField, { cy: 'member-title', label: formatMessage({ id: 'common.title' }), type: "text", placeholder: formatMessage({ id: 'first_login.enter_job_title' }), value: jobTitle, onChange: setJobTitle, onBlur: () => changeJobTitle(jobTitle), blurOnEnter: true }),
                            viewUser && !person.client ? React.createElement(TeamMemberStatusSection, { person: person }) : null,
                            React.createElement("div", { className: "dates-container", "data-userpilot": "profile-dates" },
                                React.createElement(DatePicker, { startDate: person.startDate ? moment(person.startDate) : null, label: formatMessage({ id: 'common.start_date' }), handleDateRangeChange: value => updateStartDate(value), clearBothDates: () => updateStartDate(null), datePickerStyle: DATE_PICKER_STYLE.SLIM, isSingleDatePicker: true, clearable: true, endDateLimite: person.endDate ? moment(person.endDate).add(1, 'days') : null, hideLimitMessage: true, disabled: !isAdmin }),
                                React.createElement("div", { className: "date-separator" }, "-"),
                                React.createElement(DatePicker, { startDate: person.endDate ? moment(person.endDate) : null, label: formatMessage({ id: 'common.end_date' }), handleDateRangeChange: value => updateEndDate(value), clearBothDates: () => updateEndDate(null), datePickerStyle: DATE_PICKER_STYLE.SLIM, isSingleDatePicker: true, clearable: true, startDateLimite: person.startDate ? moment(person.startDate) : null, hideLimitMessage: true, disabled: !isAdmin }))),
                        React.createElement("div", { className: "user" },
                            React.createElement("div", { className: props.isUploading ? 'upload-overlay' : 'upload-overlay-hidden' },
                                React.createElement("div", { className: props.isUploading ? 'uploading-gif' : 'uploading-gif-hidden' })),
                            person.profilePictureId ? (React.createElement("img", { crossOrigin: "use-credentials", alt: "", src: profilePicSrc(person.profilePictureId), width: "140", height: "140" })) : (React.createElement(PersonInitials, { size: INITIALS_SIZE.EXTRA_LARGE, initials: person.initials })),
                            React.createElement("div", { className: "controls" },
                                React.createElement("div", { className: "controls-update" },
                                    React.createElement("input", { id: "file-input", type: "file", onChange: handleChangeProfilePicture, ref: fileInputRef, onClick: e => {
                                            e.target.value = null;
                                        } }),
                                    React.createElement("label", { className: "control update" },
                                        React.createElement(FormattedMessage, { id: "settings_profile.update-photo" }))),
                                React.createElement("div", { className: "control remove", onClick: deleteProfilePicture },
                                    React.createElement(FormattedMessage, { id: "settings_profile.remove-photo" }))))),
                    person.client ? null : (React.createElement(React.Fragment, null,
                        React.createElement("fieldset", { className: "role-settings" },
                            React.createElement("h3", null,
                                React.createElement(FormattedMessage, { id: "settings_profile.role-labels-permissions" }),
                                ' '),
                            React.createElement("h4", null,
                                React.createElement(FormattedMessage, { id: "common.role" })),
                            React.createElement("p", { className: "description" }, viewUser ? (React.createElement(FormattedMessage, { id: "settings_profile.team-member-role-description" })) : (React.createElement(FormattedMessage, { id: "settings_profile.your-role-description" }))),
                            React.createElement(SelectInput, { cy: 'role-dropdown', listDataCy: 'role-option', value: person.role ? person.role.id : null, options: allRoles, onChange: value => {
                                    changeRole(value ? value.value : null);
                                }, placeholder: formatMessage({ id: 'settings_profile.choose-role' }), label: formatMessage({ id: 'common.role' }), multi: false, locked: !isAdmin }),
                            React.createElement("div", null,
                                React.createElement("h4", null,
                                    React.createElement(FormattedMessage, { id: "common.labels" })),
                                React.createElement("p", { className: "description" }, viewUser ? (React.createElement(FormattedMessage, { id: "settings_profile.select-labels-description-teammember" })) : (React.createElement(FormattedMessage, { id: "settings_profile.select-labels-description-viewer" }))),
                                React.createElement("div", { className: "skills-wrapper" },
                                    React.createElement(LabelDropdown, { labels: labels, selectedItems: selectedLabels, setSelectedItems: updateLabels, "data-cy": 'team-labels-dropdown' }))),
                            Util.hasDepartments(viewer.company.modules)
                                ? [
                                    React.createElement("h4", null,
                                        React.createElement(FormattedMessage, { id: "common.organization" })),
                                    React.createElement("div", { className: "organization-div" },
                                        React.createElement(SelectInput, { cy: 'department-dropdown', listDataCy: 'department-option', value: person.department ? person.department.id : null, options: departments, onChange: value => {
                                                changeDepartment(value ? value.value : null);
                                            }, placeholder: formatMessage({
                                                id: 'settings_profile.choose_department',
                                            }), label: formatMessage({ id: 'common.department' }), multi: false, locked: !viewUser && !isAdmin }),
                                        person.costCenter ? (React.createElement(InputField, { cy: 'cost-center-title', locked: true, label: formatMessage({ id: 'common.cost_center' }), type: "text", placeholder: formatMessage({ id: 'common.no_cost_center' }), value: person.costCenter, autosize: true })) : ('')),
                                ]
                                : ''))),
                    person.client ? null : (React.createElement("fieldset", { className: "permissions" },
                        React.createElement("h3", null,
                            React.createElement(FormattedMessage, { id: "settings.permission_profile" })),
                        React.createElement("div", { className: "permissions-info-wrapper" },
                            React.createElement("p", { className: "description" }, viewUser ? (React.createElement(FormattedMessage, { id: "settings_profile.permissions-desc-team" })) : (React.createElement(FormattedMessage, { id: "settings_profile.permissions-desc-viewer" })))),
                        props.showBillingInfo ? React.createElement(FormattedMessage, { id: "settings_profile.billing_info" }) : null,
                        React.createElement(SelectInput, { multi: hasModule(MODULE_TYPES.SISENSE), showValuesInline: true, value: hasModule(MODULE_TYPES.SISENSE)
                                ? person.profiles.edges.map(e => {
                                    return {
                                        value: e.node.id,
                                        label: e.node.name,
                                    };
                                })
                                : (_e = person.profiles.edges[0]) === null || _e === void 0 ? void 0 : _e.node.id, options: getSelectableProfiles(viewer.company.profiles.edges)
                                .filter(profile => viewUser || !personIsVirtualUser(profile))
                                .map(profile => {
                                return {
                                    label: profile.name,
                                    value: profile.id,
                                    profile: profile,
                                    disabled: (!viewUser && personIsAdmin(profile)) || isProfileUpdating,
                                };
                            }), onChange: value => {
                                if (value.length) {
                                    changeProfile(value);
                                }
                            }, placeholder: getProfileName(viewer.company.profiles.edges.map(profile => profile.node), (_f = person.profiles.edges[0]) === null || _f === void 0 ? void 0 : _f.node.id), label: formatMessage({ id: 'settings.permission_profile' }), locked: (!viewUser &&
                                (!hasModule(MODULE_TYPES.SISENSE) ||
                                    !hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS))) ||
                                ((!person.invited || person.email === '') && personIsVirtualUser(person)) }))),
                    Util.hasSkills() && (React.createElement("fieldset", { className: "skills" },
                        React.createElement(ProfileSkills, { person: person, company: company }))),
                    person.client ? null : (React.createElement("fieldset", { className: "working-hours" },
                        React.createElement("h3", null,
                            React.createElement(FormattedMessage, { id: "common.working_hours" })),
                        React.createElement("div", null,
                            React.createElement("table", { className: "working-hours-table" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        weekdays.map((day, index) => (React.createElement("th", { key: index }, day))),
                                        React.createElement("th", null, "\u00A0"))),
                                React.createElement("tbody", null,
                                    React.createElement("tr", null, [...Array(7)].map((_, i) => (React.createElement("td", { key: i, className: "input-cell" },
                                        React.createElement(HoursInput, { value: workingHours[i], nonWorkingDay: workingHours[i] === 0, mutation: e => saveWorkingHours(i, e), disabled: Util.isWeekdayDisabled(i, company, viewer.language) })))))))),
                        React.createElement(SelectInput, { cy: 'holiday-calendar-input', listDataCy: 'holiday-option', placeholder: formatMessage({ id: 'settings_holiday_calendars.select_holiday_calendar' }), options: holidayCalendarOptions, onChange: e => {
                                changeHolidayCalendar(e ? e.value : null);
                            }, value: person.holidayCalendar ? person.holidayCalendar.id : null, clearable: false, label: formatMessage({ id: 'settings_holiday_calendars.holiday_calendar' }), multi: false }))),
                    React.createElement("fieldset", { className: "locale-settings" },
                        React.createElement("h3", null,
                            React.createElement(FormattedMessage, { id: "settings_profile.language-dates-numbers" })),
                        React.createElement("p", { className: "description" }, viewUser
                            ? person.client
                                ? formatMessage({ id: 'settings_profile.language_description_client_user' })
                                : formatMessage({ id: 'settings_profile.language-description-team-member' })
                            : formatMessage({ id: 'settings_profile.language-description' })),
                        React.createElement(SelectInput, { listDataCy: 'localization-option', cy: 'localization-preferences', placeholder: formatMessage({ id: 'settings_profile.choose-language' }), options: languages, onChange: value => {
                                changeLanguage(value ? value.value : null);
                            }, value: person.language, clearable: false, label: formatMessage({ id: 'settings.profile-language' }), multi: false })),
                    hasModule(MODULE_TYPES.SISENSE) && (React.createElement("fieldset", null,
                        React.createElement("h3", null, 'Advanced Analytics'),
                        React.createElement("p", { className: "description" }, `${viewUser ? 'This person is' : 'You are'} ${person.sisenseUserId ? '' : 'not'} linked to an Advanced Analytics user.`),
                        person.sisenseUserId && (React.createElement(Button, { onClick: deleteSisenseUser, size: Button.SIZE.STANDARD, variant: Button.VARIANT.RED_FILLED }, "Delete User")))),
                    harvestLoading ? (React.createElement("fieldset", { className: "harvest-settings" },
                        React.createElement("div", { className: "harvest-title" },
                            React.createElement("img", { width: 30, height: 30, src: HarvestIcon, alt: "Harvest" }),
                            React.createElement("h3", { className: "harvest-header" },
                                React.createElement(FormattedMessage, { id: "title.app_harvest" }))),
                        React.createElement("h4", null,
                            React.createElement(FormattedMessage, { id: "common.loading" })))) : company.harvestEnabled ? (React.createElement("fieldset", { className: "harvest-settings" },
                        React.createElement("div", { className: "harvest-title" },
                            React.createElement("img", { width: 30, height: 30, src: HarvestIcon, alt: "Harvest" }),
                            React.createElement("h3", { className: "harvest-header" },
                                React.createElement(FormattedMessage, { id: "title.app_harvest" }))),
                        React.createElement("div", { className: 'harvest-description' },
                            React.createElement(FormattedMessage, { id: "settings.linked_harvest_user" })),
                        React.createElement("div", null, harvestUsersName))) : null,
                    !person.client &&
                        (person.calendarKey !== null || company.calendarApiEnabled || company.outlookCalendarApiEnabled) ? (React.createElement("fieldset", { className: "calendar-integration-settings" },
                        React.createElement("h3", null, "Calendar Integration"),
                        person.calendarKey !== null ? (React.createElement(React.Fragment, null,
                            React.createElement("h4", null, "Sync task deadlines to your own calendar"),
                            React.createElement("div", { className: "description" }, "Stay in the loop with your ongoing task deadlines by syncing it to your calendar of choice: G Cal, Outlook Cal and Apple Calendar."),
                            React.createElement("div", { className: "description" }, viewUser
                                ? 'This is the persons private url.'
                                : 'This is your private calendar link, paste it into your calendar tool to enable your events.'),
                            React.createElement("div", { className: "input-wrapper" },
                                React.createElement(InputField, { label: formatMessage({ id: 'common.link' }), value: 'https://api.forecast.it/calendar/' + person.calendarKey, disabled: true, type: "text", placeholder: "" }),
                                React.createElement(ClipboardButton, { className: "copy-button", title: formatMessage({ id: 'common.copy' }), "data-clipboard-text": 'https://api.forecast.it/calendar/' + person.calendarKey })))) : null,
                        company.calendarApiEnabled && !viewUser ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'section-border' }),
                            React.createElement(SettingsCalendarWrapper, { viewer: viewer, personId: (_g = person.actualPersonId) !== null && _g !== void 0 ? _g : person.id, type: "google" }))) : null,
                        company.outlookCalendarApiEnabled && !viewUser ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'section-border' }),
                            React.createElement(SettingsCalendarWrapper, { viewer: viewer, personId: (_h = person.actualPersonId) !== null && _h !== void 0 ? _h : person.id, type: "outlook" }))) : null)) : null,
                    // GitLab
                    isAdmin && company.gitlabEnabled ? (React.createElement("fieldset", { className: "gitlab-integration-settings" },
                        React.createElement("h3", null, "GitLab.com"),
                        React.createElement("p", { className: "description" },
                            React.createElement(FormattedMessage, { id: "settings_profile.gitlab_description" })),
                        person.gitlabUser && person.gitlabUser.integrationUserId ? (linkedGitlabUser ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "gitlab-user" },
                                React.createElement("img", { height: "42", width: "42", src: linkedGitlabUser.avatar_url }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, linkedGitlabUser.name),
                                    React.createElement("br", null),
                                    '@' + linkedGitlabUser.username)),
                            React.createElement(UnlinkButton, { onClick: () => removeGitLabUser(false), messageId: "settings_profile.gitlab_unlink" }))) : null) : (React.createElement("div", null,
                            React.createElement("div", { className: "input-wrapper" },
                                React.createElement(InputField, { label: formatMessage({ id: 'settings_profile.gitlab_username' }), type: "text", placeholder: formatMessage({ id: 'settings_profile.gitlab_username' }), onChange: searchString => {
                                        setGitlabSearchString(searchString || '');
                                    }, onEnter: () => fetchGitLabUsers(false) }),
                                React.createElement("button", { onClick: () => fetchGitLabUsers(false) },
                                    React.createElement(FormattedMessage, { id: "common.search" }))),
                            gitlabLoading ? (React.createElement("div", null,
                                React.createElement(FormattedMessage, { id: "common.loading" }))) : (React.createElement("div", { className: "users" }, gitlabUsers.map(u => (React.createElement("div", { className: "gitlab-user", onClick: () => selectGitlabUser(u, false) },
                                React.createElement("img", { height: "42", width: "42", src: u.avatar_url }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, u.name),
                                    React.createElement("br", null),
                                    '@' + u.username)))))))))) : null,
                    // GitLab Server
                    isAdmin && company.gitlabServerEnabled ? (React.createElement("fieldset", { className: "gitlab-integration-settings" },
                        React.createElement("h3", null, "GitLab Server"),
                        React.createElement("p", { className: "description" },
                            React.createElement(FormattedMessage, { id: "settings_profile.gitlab_description" })),
                        person.gitlabServerUser && person.gitlabServerUser.integrationUserId ? (linkedGitlabServerUser ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "gitlab-user" },
                                React.createElement("img", { height: "42", width: "42", src: linkedGitlabServerUser.avatar_url }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, linkedGitlabServerUser.name),
                                    React.createElement("br", null),
                                    '@' + linkedGitlabServerUser.username)),
                            React.createElement(UnlinkButton, { onClick: () => removeGitLabUser(true), messageId: "settings_profile.gitlab_unlink" }))) : null) : (React.createElement("div", null,
                            React.createElement("div", { className: "input-wrapper" },
                                React.createElement(InputField, { label: formatMessage({ id: 'settings_profile.gitlab_username' }), type: "text", placeholder: formatMessage({ id: 'settings_profile.gitlab_username' }), onChange: searchString => {
                                        setGitlabServerSearchString(searchString || '');
                                    }, onEnter: () => fetchGitLabUsers(true) }),
                                React.createElement("button", { onClick: () => fetchGitLabUsers(true) },
                                    React.createElement(FormattedMessage, { id: "common.search" }))),
                            gitlabServerLoading ? (React.createElement("div", null,
                                React.createElement(FormattedMessage, { id: "common.loading" }))) : (React.createElement("div", { className: "users" }, gitlabServerUsers.map(u => (React.createElement("div", { className: "gitlab-user", onClick: () => selectGitlabUser(u, true) },
                                React.createElement("img", { height: "42", width: "42", src: u.avatar_url, alt: "" }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, u.name),
                                    React.createElement("br", null),
                                    '@' + u.username)))))))))) : null,
                    // GitHub
                    !person.client && (isAdmin || !viewUser) && company.githubEnabled ? (React.createElement("fieldset", { className: "github-integration-settings" },
                        React.createElement("h3", null, "GitHub"),
                        React.createElement("p", { className: "description" },
                            React.createElement(FormattedMessage, { id: "settings_profile.github_description" })),
                        person.githubUser && person.githubUser.integrationUserId ? (linkedGithubUser ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "github-user" },
                                React.createElement("img", { height: "42", width: "42", src: linkedGithubUser.avatar_url }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, linkedGithubUser.username),
                                    React.createElement("br", null),
                                    '@' + linkedGithubUser.username)),
                            React.createElement(UnlinkButton, { onClick: removeGithubUser, messageId: "settings_profile.github_unlink" }))) : null) : (React.createElement("div", null,
                            React.createElement("div", { className: "input-wrapper" },
                                React.createElement(InputField, { label: formatMessage({ id: 'settings_profile.github_username' }), type: "text", placeholder: formatMessage({ id: 'settings_profile.github_username' }), onChange: searchString => {
                                        setGithubSearchString(searchString || '');
                                    }, onEnter: fetchGithubUsers }),
                                React.createElement("button", { onClick: fetchGithubUsers },
                                    React.createElement(FormattedMessage, { id: "common.search" }))),
                            githubLoading ? (React.createElement("div", null,
                                React.createElement(FormattedMessage, { id: "common.loading" }))) : (React.createElement("div", { className: "users" }, githubUsers.map(u => (React.createElement("div", { className: "github-user", onClick: () => selectGithubUser(u) },
                                React.createElement("img", { height: "42", width: "42", src: u.avatar_url, alt: '' }),
                                React.createElement("div", { className: "name" },
                                    React.createElement("b", null, u.username),
                                    React.createElement("br", null),
                                    '@' + u.username)))))))))) : null,
                    // Jira
                    !person.client && (isAdmin || !viewUser) && company.jiraCloudEnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Jira Cloud"),
                        React.createElement("p", { className: "description" }, person.jiraCloudId ? (jiraCloudUsers ? (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: 'settings_profile.jira_linked_user', values: {
                                    jiraUser: jiraCloudUsername,
                                } }),
                            React.createElement(UnlinkButton, { onClick: () => unlinkJiraUser('cloud') }))) : null) : (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.jira_description" }),
                            React.createElement("div", { className: "common-integration-user-dropdown" },
                                React.createElement(Dropdown, { value: null, options: jiraCloudUsers.filter(u => !u.linked), onChange: val => linkJiraUser(val, 'cloud'), hideLabel: true, placeholder: formatMessage({ id: 'integrations.jira_user' }), customHeight: 30, integrationDropdown: true, integrationLoading: jiraCloudLoading, integrationRetryToFetch: fetchJiraCloudUsers }))))))) : null,
                    // Jira Server
                    !person.client && (isAdmin || !viewUser) && company.jiraServerEnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Jira Server"),
                        React.createElement("p", { className: "description" }, person.jiraServerId ? (jiraServerUsers ? (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: 'settings_profile.jira_linked_user', values: {
                                    jiraUser: jiraServerUsername,
                                } }),
                            React.createElement(UnlinkButton, { onClick: () => unlinkJiraUser('server') }))) : null) : (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.jira_description" }),
                            React.createElement("div", { className: "common-integration-user-dropdown" },
                                React.createElement(Dropdown, { value: null, options: jiraServerUsers.filter(u => !u.linked), onChange: val => linkJiraUser(val, 'server'), hideLabel: true, placeholder: formatMessage({ id: 'integrations.jira_user' }), customHeight: 30, integrationDropdown: true, integrationLoading: jiraServerLoading, integrationRetryToFetch: fetchJiraServerUsers }))))))) : null,
                    // Unit4 - Show for admin or when viewing your own profile
                    !person.client && (isAdmin || !viewUser) && company.unit4Enabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Unit4"),
                        React.createElement("p", { className: "description" }, person.unit4UserObject ? (unit4Users ? (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.unit4_linked_user", values: {
                                    unit4User: unit4Username,
                                } }),
                            React.createElement(UnlinkButton, { onClick: unlinkUnit4User }))) : null) : (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.unit4_description" }),
                            React.createElement("div", { className: "common-integration-user-dropdown" },
                                React.createElement(Dropdown, { value: null, options: unit4UserOptions, onChange: val => linkUnit4User(val), hideLabel: true, placeholder: formatMessage({
                                        id: 'integrations.unit4_user.placeholder',
                                    }), customHeight: 30, integrationDropdown: true, integrationLoading: unit4Loading, onSearchValueChange: fetchUnit4Resources }))))))) : null,
                    // BambooHR - Show for admin or when viewing your own profile
                    !person.client && (isAdmin || !viewUser) && company.bambooHREnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "BambooHR"),
                        React.createElement("p", { className: "description" }, person.bambooHRId ? (bambooHRUsers ? (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.bamboohr_linked_user", values: {
                                    bamboohrUser: bambooHRUsername,
                                } }),
                            React.createElement("div", null,
                                React.createElement(UnlinkButton, { onClick: () => setBambooHRUser() })))) : null) : (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.bamboohr_description" }),
                            React.createElement("div", { className: "common-integration-user-dropdown" },
                                React.createElement(Dropdown, { value: null, options: bambooHRUsers, onChange: val => setBambooHRUser(val), hideLabel: true, placeholder: formatMessage({ id: 'integrations.bamboohr_user' }), customHeight: 30, integrationDropdown: true, integrationLoading: bambooHRLoading, integrationRetryToFetch: fetchBambooHRUsers }))))))) : null,
                    // Sage Intacct - Show for non-client, admin, or when viewing your own profile
                    !person.client &&
                        (isAdmin || !viewUser) &&
                        hasFeatureFlag('sage_intacct_integration') &&
                        company.sageIntacctConnectionEnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Sage Intacct"),
                        React.createElement("p", { className: "description" }, person.sageIntacctId ? (React.createElement(React.Fragment, null,
                            `${person.firstName} is linked to the following Sage Intacct profile: ${person.sageIntacctName} ${(person === null || person === void 0 ? void 0 : person.sageIntacctEmail) ? `(${person.sageIntacctEmail})` : ''}`,
                            React.createElement("div", null,
                                React.createElement(UnlinkButton, { onClick: () => setSageIntacctUser() })))) : (React.createElement(React.Fragment, null,
                            `Select a profile
                            from the list below to associate this team member
                            with a Sage Intacct profile.`,
                            React.createElement("div", { className: "common-integration-user-dropdown" },
                                React.createElement(Dropdown, { value: null, options: sageIntacctUsers, onChange: val => setSageIntacctUser(val), hideLabel: true, placeholder: `Select Sage Intacct profile`, customHeight: 30, integrationDropdown: true, integrationLoading: sageIntacctLoading, integrationRetryToFetch: fetchSageIntacctUsers }))))))) : null,
                    hasFeatureFlag('hibob_integration') && (React.createElement(HiBobIntegration, { person: person, viewUser: viewUser, company: company })),
                    // Slack - Show when viewing your own profile
                    !person.client && company.newSlackEnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Slack"),
                        React.createElement("p", { className: "description" }, person.slackId ? (slackUsers ? (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.slack_linked_user", values: {
                                    slackUser: slackUsername,
                                } }),
                            React.createElement("div", null,
                                React.createElement(UnlinkButton, { onClick: () => unlinkSlackUser() })))) : null) : (React.createElement(React.Fragment, null,
                            React.createElement(FormattedMessage, { id: "settings_profile.slack_description" }),
                            React.createElement("div", null,
                                React.createElement(Button, { onClick: () => linkSlackUser(), size: Button.SIZE.STANDARD, variant: Button.VARIANT.GREEN_FILLED }, formatMessage({ id: 'common.link' })))))))) : null,
                    // Azure DevOps - Show for non-client, admin, or when viewing your own profile
                    !person.client && (isAdmin || !viewUser) && company.vstsEnabled ? (React.createElement("fieldset", { className: "common-integration-settings" },
                        React.createElement("h3", null, "Azure DevOps"),
                        React.createElement("p", { className: "description" }, person.adoUserId ? (React.createElement(React.Fragment, null,
                            `${person.firstName} is linked to an Azure DevOps team member`,
                            React.createElement("div", null,
                                React.createElement(UnlinkButton, { onClick: () => unlinkAdoUser() })))) : (React.createElement(React.Fragment, null,
                            `Link this user to an Azure DevOps team member`,
                            React.createElement("div", null,
                                React.createElement(Button, { onClick: () => linkAdoUser(), size: Button.SIZE.STANDARD, variant: Button.VARIANT.GREEN_FILLED }, formatMessage({ id: 'common.link' })))))))) : null,
                    forecast_user === 'true' && (React.createElement("div", { title: 'web fron version', className: 'web-fron-version' },
                        "version ",
                        webFrontVersion,
                        " ",
                        webFrontVersionTime.format('DD/MM/YY HH:mm'))))))));
};
