import React, { useEffect, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, ComboBox, DeprecatedCreativeModal as CreativeModal, DeprecatedScrollbar as Scrollbar, FlexColumn, FlexRow, Label, SearchField, SubHeading, Text, Alert, } from '@forecast-it/design-system';
import { hideLoader, showLoader } from '../../global_loader';
import styled from 'styled-components/macro';
import InlineLoader from '../../../forecast-app/shared/components/inline-loader/inline_loader';
import Util from '../../../forecast-app/shared/util/util';
import { getSelectableProfiles, personIsClientUser, personIsCollaboratorUser, personIsVirtualUser, } from '../../../forecast-app/shared/util/PermissionsUtil';
import { HorizontalBar } from '../sage_intacct/SageIntacctModal.styled';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import BulkCreatePersonsMutation from '../../../mutations/ts/bulk_create_persons_mutation';
import BulkCreateDepartmentsMutation from '../../../mutations/ts/bulk_create_departments_mutation';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { SEAT } from '../../../constants';
import DirectApi from '../../../directApi';
const scrollSectionHeight = (window === null || window === void 0 ? void 0 : window.innerHeight) < 900 ? '140px' : '500px';
const ListWrapper = styled.div `
	height: ${scrollSectionHeight};
`;
const ListItem = styled.div `
	width: 550px;
	padding: 18px 8px 18px 8px;
	border-bottom: ${({ hasBorderBottom }) => (hasBorderBottom ? '1px solid #ccc' : 'none')};
`;
const HiBobImportTeamMembersModal = ({ closeModal, company, seatType, isChargeBeeCustomer, }) => {
    const { formatMessage } = useIntl();
    const [teamMembers, setTeamMembers] = useState([]);
    const [filteredTeamMembers, setFilteredTeamMembers] = useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [importing, setImporting] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [fetchingEmployees, setFetchingEmployees] = useState(true);
    const [failed, setFailed] = useState(false);
    const [maxNoOfEmployees, setMaxNoOfEmployees] = useState(false);
    const [permissionsErrorMessage, setPermissionsErrorMessage] = useState('');
    const activated = company.allPersons.edges
        .filter(person => person.node.active && person.node.client == null)
        .map(p => p.node);
    const disableImportButton = importing || selectedTeamMembers.length === 0 || !selectedProfileId || (maxNoOfEmployees && isChargeBeeCustomer);
    const departmentsToCreate = [];
    const availableUserSeats = company.userSeats -
        activated.filter(person => !personIsVirtualUser(person) && !personIsClientUser(person) && !personIsCollaboratorUser(person)).length;
    const availableVirtualSeats = company.virtualSeats - activated.filter(person => personIsVirtualUser(person)).length;
    const availableCollaboratorSeats = company.collaboratorSeats - activated.filter(person => personIsCollaboratorUser(person)).length;
    const seatMax = seatType === SEAT.CORE
        ? availableUserSeats
        : seatType === SEAT.VIRTUAL
            ? availableVirtualSeats
            : availableCollaboratorSeats;
    const departmentMap = company.departments.edges.reduce((map, edge) => {
        const node = edge.node;
        if (node.name && node.id) {
            map[node.name] = node.id;
        }
        return map;
    }, {});
    const selectAll = useMemo(() => {
        if (filteredTeamMembers.length === 0 || selectedTeamMembers.length === 0)
            return false;
        return filteredTeamMembers.every(tm => selectedTeamMembers.find(e => e.id === tm.id));
    }, [filteredTeamMembers, selectedTeamMembers]);
    const teamMemberClick = (inputId) => {
        if (selectedTeamMembers.find(e => e.id === inputId)) {
            const selectedUsers = selectedTeamMembers.filter(e => e.id !== inputId);
            setSelectedTeamMembers(selectedUsers);
        }
        else {
            const selectedUsers = [...selectedTeamMembers];
            const teamMember = teamMembers.find(e => e.id === inputId);
            if (teamMember) {
                selectedUsers.push(teamMember);
                setSelectedTeamMembers(selectedUsers);
            }
        }
    };
    const reformatDate = (date) => {
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}`;
    };
    const mapToForecastEmployees = (teamMembers) => {
        return teamMembers.map(member => ({
            companyId: String(Util.getIdFromBase64String(company.id)),
            firstName: member.firstName,
            lastName: member.surname,
            jobTitle: member.jobTitle,
            startDate: reformatDate(member.startDate),
            profileIds: [Number(Util.getIdFromBase64String(selectedProfileId))],
            departmentId: Number(Util.getIdFromBase64String(departmentMap[member.department])),
            email: member.email,
            externalRefs: [{ key: 'hibob_employee_id', value: member.id }],
        }));
    };
    const getEmailErrorMessage = error => {
        switch (error) {
            case 'ACCOUNT_ALREADY_EXIST':
                return 'Email/s already in use.';
            case 'INVALID':
                return 'Email/s is invalid.';
            default:
                return 'An error occurred.';
        }
    };
    const showErrorModal = errors => {
        showModal({
            type: MODAL_TYPE.WARNING,
            warningInformation: [getEmailErrorMessage(errors[0])],
        });
    };
    const createEmployees = () => {
        Util.CommitMutation(BulkCreatePersonsMutation, {
            persons: mapToForecastEmployees(selectedTeamMembers),
            companyId: company.id,
        }, res => {
            setImporting(false);
            closeModal();
            hideLoader();
            if (res.bulkCreatePersons.errors && res.bulkCreatePersons.errors.length > 0) {
                showErrorModal(res.bulkCreatePersons.errors);
            }
            else {
                createToast({
                    duration: 5000,
                    message: formatMessage({ id: 'integrations.hibob.import_toast' }, { value: selectedTeamMembers.length }),
                });
            }
        });
    };
    const newDepartments = () => {
        departmentsToCreate.length = 0;
        selectedTeamMembers.forEach(member => {
            if (!departmentMap[member.department]) {
                if (!departmentsToCreate.some(dept => dept.name === member.department)) {
                    departmentsToCreate.push({ name: member.department });
                }
            }
        });
        return departmentsToCreate.length > 0;
    };
    const onImportClick = () => {
        if (!selectedProfileId) {
            setPermissionsErrorMessage(formatMessage({ id: 'integrations.hibob.select_permission_error' }));
            return;
        }
        setImporting(true);
        showLoader();
        if (newDepartments()) {
            Util.CommitMutation(BulkCreateDepartmentsMutation, {
                departments: departmentsToCreate,
                companyId: company.id,
            }, res => {
                if (res.bulkCreateDepartments.errors && res.bulkCreateDepartments.length > 0) {
                    showErrorModal(res.bulkCreateDepartments.errors);
                }
                else {
                    res.bulkCreateDepartments.departments.forEach(department => {
                        const node = department.node;
                        if (node.name && node.id) {
                            departmentMap[node.name] = node.id;
                        }
                    });
                    createEmployees();
                }
            });
        }
        else {
            createEmployees();
        }
    };
    const onFilterChange = (input) => {
        setFilteredTeamMembers(teamMembers.filter(tm => { var _a; return (_a = tm.fullName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(input.toLowerCase()); }));
    };
    const selectAllClick = () => {
        if (filteredTeamMembers.length === 0)
            return;
        const allFilteredIds = filteredTeamMembers.map(tm => tm);
        const isAllFilteredIdsInSelected = allFilteredIds.every(id => selectedTeamMembers.includes(id));
        if (isAllFilteredIdsInSelected) {
            const updatedSelectedTeamMembers = selectedTeamMembers.filter(id => !allFilteredIds.includes(id));
            setSelectedTeamMembers(updatedSelectedTeamMembers);
        }
        else {
            setSelectedTeamMembers(allFilteredIds);
        }
    };
    useEffect(() => {
        DirectApi.Fetch('hibob/employees').then(res => {
            if (res === null || res === void 0 ? void 0 : res.employees) {
                const tempTms = res.employees.map(t => {
                    return {
                        fullName: t.fullName,
                        jobTitle: t.work.title,
                        startDate: t.work.startDate,
                        department: t.work.department,
                        email: t.email,
                        surname: t.surname,
                        id: t.id,
                        firstName: t.firstName,
                    };
                });
                setTeamMembers(tempTms);
                setFilteredTeamMembers(tempTms);
                setFetchingEmployees(false);
            }
            else if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.status) === 500 || (res === null || res === void 0 ? void 0 : res.statusCode) === 500) {
                setFailed(true);
                setFetchingEmployees(false);
            }
            else {
                setTeamMembers([]);
                setFilteredTeamMembers([]);
                setFetchingEmployees(false);
            }
        });
    }, []);
    useEffect(() => {
        var _a;
        if (((_a = company === null || company === void 0 ? void 0 : company.profiles) === null || _a === void 0 ? void 0 : _a.edges) && Array.isArray(company.profiles.edges)) {
            const seat = seatType === SEAT.CORE && company.tier !== 'CORE' ? 'FULL' : seatType;
            const sortedProfiles = getSelectableProfiles(company.profiles.edges, seat);
            setProfiles(sortedProfiles);
        }
    }, []);
    useEffect(() => {
        setPermissionsErrorMessage('');
    }, [selectedProfileId]);
    useEffect(() => {
        if (selectedTeamMembers.length > seatMax) {
            setMaxNoOfEmployees(true);
        }
        else {
            setMaxNoOfEmployees(false);
        }
    }, [selectedTeamMembers]);
    return (React.createElement(CreativeModal, { title: formatMessage({ id: 'integrations.hibob.import_team_members' }), size: 'l', closeModal: closeModal, creativeButtonText: formatMessage({ id: 'integrations.hibob.import_selected_team_members' }, { count: selectedTeamMembers.length }), cancelButtonText: formatMessage({ id: 'common.cancel' }), onCreateHandler: onImportClick, onCancelHandler: closeModal, replicateDesignSystem: true, disabled: disableImportButton },
        isChargeBeeCustomer && (React.createElement(Alert, { type: Alert.TYPE.INFORMATION, text: formatMessage({ id: 'integrations.hibob.alert' }, { count: seatMax, type: seatType ? seatType.toLowerCase() : 'core' }) })),
        React.createElement(ComboBox, { label: "Permissions", helperText: formatMessage({ id: 'common.permissions_level' }), "data-cy": "combobox-profiles", defaultItems: profiles, value: selectedProfileId, onChange: setSelectedProfileId, width: ComboBox.WIDTH.FLEX, errorText: permissionsErrorMessage, hasError: !!permissionsErrorMessage }, (profile) => (React.createElement(ComboBox.Item, { textValue: profile.name || '' },
            React.createElement(Text, null, profile.name)))),
        React.createElement(FlexColumn, { gap: 'xs' },
            React.createElement(Label, null,
                React.createElement(FormattedMessage, { id: "common.team_members" })),
            React.createElement(Text, { color: Text.color.medium },
                React.createElement(FormattedMessage, { id: "integrations.hibob.select_team_members" })),
            React.createElement(SearchField, { isExpandable: false, onChange: onFilterChange, width: "600px" })),
        failed && React.createElement(Text, { color: 'error' }, "Something has gone wrong, try again or contact support."),
        maxNoOfEmployees && isChargeBeeCustomer && (React.createElement(Text, { color: 'error' }, formatMessage({ id: 'integrations.hibob.select_maximmum_error' }, { seatMax: seatMax }))),
        React.createElement(ListItem, null,
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Checkbox, { onChange: selectAllClick, checked: selectAll }),
                React.createElement(SubHeading, { size: '2' }, 'Select all'))),
        React.createElement(ListWrapper, null, fetchingEmployees ? (React.createElement(InlineLoader, null)) : (React.createElement(Scrollbar, { maxHeight: scrollSectionHeight },
            React.createElement(FlexColumn, { alignItems: "flex-start", justifyContent: "flex-start" }, filteredTeamMembers &&
                filteredTeamMembers.map(tm => {
                    return (React.createElement(ListItem, { key: 'team-member-' + tm.id, hasBorderBottom: true },
                        React.createElement(FlexRow, { gap: 'xl' },
                            React.createElement(Checkbox, { onChange: () => teamMemberClick(tm.id), checked: selectedTeamMembers.includes(tm) }),
                            React.createElement(Text, { size: '2' }, tm.fullName))));
                }))))),
        React.createElement(HorizontalBar, null)));
};
export default HiBobImportTeamMembersModal;
