import React, {useState, useMemo, useEffect} from 'react';
import {BillableTimeText, HiddenText} from '../team-timesheet/timesheets_team_page_styled';
import {
	getEditTimeRegsPropsFromRowData,
	getNoApprovalStatusChangeMessage,
	getPersonData,
	getProjectData,
	getTimeData,
	UpdateApprovalStatus,
} from './TimeApprovalLogic';
import {APPROVAL_STATUS, APPROVAL_TYPE} from '../../../constants';
import {AssigneeGroup, Button, CheckmarkIcon, CrossIcon, EmailIcon, HexagonText, Table, TimesheetsIcon} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {TableWrapper, TimesheetsIconWrapper} from './TimeApprovalPage_styled';
import NotesPopOut from '../../../forecast-app/shared/components/popups/notes_pop_out';
import {useHistory} from 'react-router-dom';
import {
	IdleTimeWrapper,
	ProjectIndicatorWrapper,
} from '../../../containers/modal/time_approval/ActivateTimeApprovalModal_styled';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {ForecastTooltipFormulaRenderer} from '../../ForecastTooltipFormulaRenderer';
import styled from 'styled-components';
import {isBillableSplitAllowed} from '../../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';
import TimeRegistrationRolesDisplay from '../../../forecast-app/shared/components/display/TimeRegistrationRolesDisplay';
import {
	getLockErrorMessage,
	updateTimeRegistration,
} from '../../../forecast-app/shared/components/edit-time-entry/EditTimeEntriesLogic';
import EditTimeEntries from '../../../forecast-app/shared/components/edit-time-entry/EditTimeEntries';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {DeprecatedProjectIndicatorComponent} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';
import Spinner, {SpinnerStyle} from '../../../forecast-app/shared/components/spinner/Spinner';

const GROUP_TYPE = {
	PERSON: 'PERSON',
	PROJECT: 'PROJECT',
	INTERNAL_TIME: 'INTERNAL_TIME',
	TIME_OFF: 'TIME_OFF',
};

const getTopLevelApprovalDisabledMessage = (topLevelEntity, secondLevelEntity, approvalType) => {
	return (
		<ForecastTooltipFormulaRenderer
			items={[
				{
					description: `You cannot ${approvalType} the timesheets for this ${topLevelEntity} because your filter options might be hiding time registrations.`,
				},
				{
					description: `Expand the row and ${approvalType} ${secondLevelEntity} individually or modify your applied filter.`,
				},
			]}
			translatedMessage={true}
		/>
	);
};

const getTimeRegsFromRowData = (rowData, isGroupedByProject) => {
	if (isGroupedByProject) {
		return rowData.person.timeRegs;
	}

	if (rowData.idleTime) {
		return rowData.idleTime.timeRegs;
	}

	return rowData.project.timeRegs;
};

const getSecondGroupData = datas => {
	if (datas.project) {
		const parentInfo = {id: datas.project.id, groupType: GROUP_TYPE.PROJECT};
		return getPersonData(datas.project.timeRegs, parentInfo);
	}

	if (datas.idleTime) {
		const parentInfo = {
			id: datas.idleTime.id,
			groupType: datas.idleTime.isInternalTime ? GROUP_TYPE.INTERNAL_TIME : GROUP_TYPE.TIME_OFF,
		};
		return getPersonData(datas.idleTime.timeRegs, parentInfo);
	}

	if (datas.person) {
		const parentInfo = {id: datas.person.id, groupType: GROUP_TYPE.PERSON};
		return getProjectData(datas.person.timeRegs, parentInfo, true);
	}
};

const TimeCellWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.edit-button {
		opacity: 0;
	}

	&:hover .edit-button {
		opacity: 1;
	}
`;

const ActionButton = ({onClick, isLoading, disabled, icon, ...props}) => {
	const [actionInProgress, setActionInProgress] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			setActionInProgress(false);
		}
	}, [isLoading]);

	return (
		<Button
			{...props}
			disabled={disabled || isLoading}
			icon={actionInProgress ? () => <Spinner spinning style={SpinnerStyle.onLight} size={18} /> : icon}
			onClick={(...args) => {
				setActionInProgress(true);
				onClick(...args);
			}}
		></Button>
	);
};

/**
 * The content of this component would really benefit from being separated out into separate functions
 */
const TimeApprovalTable = ({
	sectionType,
	onRemind,
	currentViewingDate,
	enabledColumns,
	intl,
	isGroupedByProject,
	firstGroupData,
	topLevelApprovalDisabled,
	actualPersonId,
	companyLockedDate,
	showProjectActions,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const billableSplitAllowed = isBillableSplitAllowed();
	const timeData = rowData => {
		const timeRegs = getTimeRegsFromRowData(rowData, isGroupedByProject);
		return getTimeData(timeRegs);
	};

	const data = {rows: firstGroupData};

	const hasTimeRegUpdatePermission = hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL);

	const history = useHistory();
	const showTaskModal = taskId => {
		Util.showTaskModal(taskId, history);
	};

	const buttonValidation = useMemo(() => {
		switch (sectionType) {
			case APPROVAL_STATUS.SUBMITTED:
				return {
					action: APPROVAL_TYPE.APPROVE,
					text: intl.formatMessage({id: 'project_budget.approve_expense_button'}),
				};
			default:
				return {action: null, text: ''};
		}
	}, [sectionType]);

	const buttonReminder = useMemo(() => {
		switch (sectionType) {
			case APPROVAL_STATUS.NOT_SUBMITTED:
				return {action: APPROVAL_TYPE.SUBMIT, text: intl.formatMessage({id: 'time_approval.submit'})};
			default:
				return {action: null, text: ''};
		}
	});
	const buttonRejection = useMemo(() => {
		switch (sectionType) {
			case APPROVAL_STATUS.SUBMITTED:
				return {action: APPROVAL_TYPE.UNSUBMIT, text: intl.formatMessage({id: 'time_approval.unsubmit'})};
			case APPROVAL_STATUS.APPROVED:
				return {action: APPROVAL_TYPE.UNAPPROVE, text: intl.formatMessage({id: 'time_approval.unapprove'})};
			default:
				return {action: null, text: ''};
		}
	}, [sectionType]);

	const onClickHandler = (e, personId, projectId, idleTimeId, isValidationButton, actionsDisabled, person, isTimeOffOnly) => {
		if (actionsDisabled || isLoading) return;
		setIsLoading(true);

		trackEvent(
			`${isValidationButton ? 'Approve' : 'Reject'} ${
				projectId ? 'Project' : idleTimeId ? 'Idle' : 'Person'
			} Time Button`,
			'Clicked',
			{
				timeRegPerson: !person ? undefined : personId === actualPersonId ? 'myself' : person.email,
			}
		);

		e.stopPropagation();
		if (isValidationButton) {
			UpdateApprovalStatus(
				personId && [personId],
				projectId && [projectId],
				idleTimeId && [idleTimeId],
				buttonValidation.action,
				currentViewingDate,
				intl,
				person,
				isTimeOffOnly,
				() => setIsLoading(false)
			);
		} else {
			UpdateApprovalStatus(
				personId && [personId],
				projectId && [projectId],
				idleTimeId && [idleTimeId],
				buttonRejection.action,
				currentViewingDate,
				intl,
				person,
				isTimeOffOnly,
				() => setIsLoading(false)
			);
		}
	};

	const timesheetsRedirect = (e, person) => {
		trackEvent('Person Time Reg Icon', 'Clicked', {
			timeRegPerson: person.id === actualPersonId ? 'myself' : person.email,
		});
		e.stopPropagation();
		const firstDayOfWeekFormatted = currentViewingDate.format('YYYY-MM-DD');
		window.open(`/timesheets?personId=${person.id}&date=${firstDayOfWeekFormatted}`);
	};

	const handleRemind = person => {
		onRemind(person);
	};

	const weekHeaderSection = () => {
		return Array(7)
			.fill(0)
			.map((d, index) => {
				const day = currentViewingDate.clone().add(index, 'day');
				return (
					<Table.HeaderColumn
						align="right"
						visible={true}
						width={isBillableSplitAllowed() ? 'MEDIUM_SMALL' : 'SMALL'}
						bordered
					>
						<Table.HeaderColumn.Title>
							{day.format('ddd')} <br /> {day.format('D MMM')}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				);
			});
	};

	const weekRowsColumns = data => {
		return Array(7)
			.fill(0)
			.map((d, index) => {
				const total = data.totals[index];
				const billableTotal = data.billableTotals ? data.billableTotals[index] : null;
				const {dayOfInput, dayRegNodes} = getEditTimeRegsPropsFromRowData(data, index);
				const isApproved = sectionType === APPROVAL_STATUS.APPROVED;
				const errorMessage = isApproved
					? intl.formatMessage({id: 'approval.approved_time'})
					: getLockErrorMessage(
							dayOfInput?.toDate(),
							companyLockedDate?.toDate(),
							dayRegNodes,
							intl.formatMessage,
							false //exclusions are not available with approval, so we can just set this to false
					  );
				const showBillableTime = billableSplitAllowed && billableTotal !== null && total !== billableTotal;
				return (
					<Table.Column usePadding>
						<TimeCellWrapper>
							{dayRegNodes ? (
								<EditTimeEntries
									dayOfInput={dayOfInput?._isAMomentObject ? dayOfInput?.toDate() : dayOfInput}
									dayRegNodes={dayRegNodes}
									infoText={errorMessage}
									mutation={params => updateTimeRegistration({skipApprovalValidation: true, ...params})}
									disabled={!!errorMessage}
								/>
							) : null}
							<div className={'time-worked'}>
								<div>
									<div>
										{total != null ? (
											<span style={{display: 'flex', justifyContent: 'flex-end'}}>
												{Util.convertMinutesToFullHour(total, intl, true)}
												{billableSplitAllowed ? <HiddenText> bil.</HiddenText> : null}
											</span>
										) : (
											''
										)}
									</div>
									{billableSplitAllowed ? (
										<BillableTimeText data-cy={'billable-hours'}>
											{showBillableTime ? (
												`${Util.convertMinutesToFullHour(billableTotal, intl, true)} bil.`
											) : (
												<HiddenText> bil.</HiddenText>
											)}
										</BillableTimeText>
									) : null}
								</div>
							</div>
						</TimeCellWrapper>
					</Table.Column>
				);
			});
	};

	const totalsColumn = (total, billableTotal) => {
		return (
			<Table.Column usePadding>
				<div className={'time-worked'}>
					<div>
						<div>
							{total != null ? (
								<span style={{display: 'flex', justifyContent: 'flex-end'}}>
									{Util.convertMinutesToFullHour(total, intl, true)}
									{billableSplitAllowed ? <HiddenText> bil.</HiddenText> : null}
								</span>
							) : (
								'-'
							)}
						</div>
						{billableSplitAllowed ? (
							<BillableTimeText data-cy={'billable-hours'}>
								{`${Util.convertMinutesToFullHour(billableTotal, intl, true)} bil.`}
							</BillableTimeText>
						) : null}
					</div>
				</div>
			</Table.Column>
		);
	};

	const idleTimeRow = rowData => {
		const noApprovalStatusChange = rowData.idleTime?.noApprovalStatusChange;
		const actionsDisabled = topLevelApprovalDisabled || noApprovalStatusChange;

		return [
			<Table.Column>
				<IdleTimeWrapper>
					<span>{rowData.idleTime.isInternalTime ? 'Internal' : intl.formatMessage({id: 'common.time_off'})}</span>
					<span>{rowData.idleTime.name}</span>
				</IdleTimeWrapper>
			</Table.Column>,
			<Table.Column></Table.Column>,
			<Table.Column></Table.Column>,
			<Table.Column></Table.Column>,
			weekRowsColumns(rowData.idleTime),
			<Table.Column>
				{rowData.idleTime.timeRegs.length > 0 && (
					<NotesPopOut
						timeRegItem={{timeRegistrationsWithNotes: rowData.idleTime.timeRegs}}
						taskName={rowData.idleTime.name}
						readOnly
					/>
				)}
			</Table.Column>,
			totalsColumn(rowData.idleTime.total, 0),
			<Table.Column>
				{buttonRejection.action &&
					(isGroupedByProject ? (
						//fake button to keep the flex space evenly of the column and keep the approve button on the right
						<div style={{width: '32px'}}></div>
					) : (
						<ForecastTooltip
							content={
								topLevelApprovalDisabled
									? getTopLevelApprovalDisabledMessage('idle time', 'people', 'reject')
									: noApprovalStatusChange
									? getNoApprovalStatusChangeMessage(noApprovalStatusChange, intl.formatMessage)
									: intl.formatMessage({id: 'approval.reject'})
							}
						>
							<ActionButton
								isSquare
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								iconPosition={Button.ICON_POSITION.FIRST}
								icon={() => (
									<CrossIcon size={CrossIcon.SIZE.SMALL} color={actionsDisabled ? '#A1A1A1' : '#F40000'} />
								)}
								disabled={actionsDisabled}
								isLoading={isLoading}
								onClick={e =>
									onClickHandler(
										e,
										rowData.idleTime.parentId,
										null,
										rowData.idleTime.id,
										false,
										actionsDisabled,
										!!rowData.idleTime.parentId ? rowData.idleTime.timeRegs[0].person : null,
										rowData.idleTime.parentGroupType === GROUP_TYPE.TIME_OFF
									)
								}
								cy={'idle-time-reject-button'}
							></ActionButton>
						</ForecastTooltip>
					))}
				{buttonValidation.action && (
					<ForecastTooltip
						content={
							topLevelApprovalDisabled
								? getTopLevelApprovalDisabledMessage('idle time', 'people', 'approve')
								: intl.formatMessage({id: 'approval.approve'})
						}
					>
						<ActionButton
							isSquare
							variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
							iconPosition={Button.ICON_POSITION.FIRST}
							icon={() => <CheckmarkIcon />}
							disabled={actionsDisabled}
							isLoading={isLoading}
							onClick={e =>
								onClickHandler(
									e,
									rowData.idleTime.parentId,
									null,
									rowData.idleTime.id,
									true,
									actionsDisabled,
									!!rowData.idleTime.parentId ? rowData.idleTime.timeRegs[0].person : null,
									rowData.idleTime.parentGroupType === GROUP_TYPE.TIME_OFF
								)
							}
							cy={'idle-time-approve-button'}
						></ActionButton>
					</ForecastTooltip>
				)}
			</Table.Column>,
		];
	};

	const projectRow = (rowData, topLevelApprovalDisabled) => {
		const noApprovalStatusChange = rowData.project?.noApprovalStatusChange;
		const actionsDisabled = topLevelApprovalDisabled || noApprovalStatusChange;
		return [
			<Table.Column>
				<ProjectIndicatorWrapper>
					<DeprecatedProjectIndicatorComponent
						projectName={rowData.project.name}
						id={rowData.project.id}
						showProjectName={true}
						companyProjectId={rowData.project.companyProjectId}
						projectColor={rowData.project.projectColor}
						customProjectId={rowData.project.customProjectId}
					/>
				</ProjectIndicatorWrapper>
			</Table.Column>,
			<Table.Column>
				<div style={{marginLeft: '16px'}}>
					<AssigneeGroup showCount={4} assignees={rowData.project.projectOwner} />
				</div>
			</Table.Column>,
			<Table.Column></Table.Column>,
			<Table.Column></Table.Column>,
			weekRowsColumns(rowData.project),
			<Table.Column></Table.Column>,
			totalsColumn(rowData.project.total, rowData.project.billableTotal),
			<Table.Column>
				{showProjectActions &&
					buttonRejection.action &&
					(isGroupedByProject ? (
						//fake button to keep thet flex space evenly of the column and keep the approve button on the right
						<div style={{width: '32px'}}></div>
					) : (
						<ForecastTooltip
							content={
								topLevelApprovalDisabled
									? getTopLevelApprovalDisabledMessage('project', 'people', 'reject')
									: noApprovalStatusChange
									? getNoApprovalStatusChangeMessage(noApprovalStatusChange, intl.formatMessage)
									: intl.formatMessage({id: 'approval.reject'})
							}
						>
							<ActionButton
								isSquare
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								iconPosition={Button.ICON_POSITION.FIRST}
								icon={() => (
									<CrossIcon size={CrossIcon.SIZE.SMALL} color={actionsDisabled ? '#A1A1A1' : '#F40000'} />
								)}
								disabled={actionsDisabled}
								isLoading={isLoading}
								onClick={e =>
									onClickHandler(
										e,
										rowData.project.parentId,
										rowData.project.id,
										null,
										false,
										actionsDisabled,
										!!rowData.project.parentId ? rowData.project.timeRegs[0].person : null
									)
								}
								cy={'project-reject-button'}
							></ActionButton>
						</ForecastTooltip>
					))}
				{showProjectActions && buttonValidation.action && (
					<ForecastTooltip
						content={
							topLevelApprovalDisabled
								? getTopLevelApprovalDisabledMessage('project', 'people', 'approve')
								: noApprovalStatusChange
								? getNoApprovalStatusChangeMessage(noApprovalStatusChange, intl.formatMessage)
								: intl.formatMessage({id: 'approval.approve'})
						}
					>
						<ActionButton
							isSquare
							variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
							iconPosition={Button.ICON_POSITION.FIRST}
							icon={() => <CheckmarkIcon />}
							disabled={actionsDisabled}
							isLoading={isLoading}
							onClick={e =>
								onClickHandler(
									e,
									rowData.project.parentId,
									rowData.project.id,
									null,
									true,
									actionsDisabled,
									!!rowData.project.parentId ? rowData.project.timeRegs[0].person : null
								)
							}
							cy={'project-approve-button'}
						></ActionButton>
					</ForecastTooltip>
				)}
			</Table.Column>,
		];
	};

	const personRow = (rowData, topLevelApprovalDisabled) => {
		const noApprovalStatusChange = rowData.person.noApprovalStatusChange;
		const actionsDisabled = topLevelApprovalDisabled || noApprovalStatusChange;
		return [
			<Table.Column>
				<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
					<div style={{minWidth: '0px'}}>
						<HexagonText
							key={rowData.person.id}
							text={`${rowData.person.firstName} ${rowData.person.lastName}`}
							imgUrl={rowData.person.profilePictureId}
						/>
					</div>
					<TooltipContainer
						infoText={intl.formatMessage(
							{id: 'timesheet.go_to_persons_timesheet'},
							{firstName: rowData.person.firstName}
						)}
						growable
					>
						{hasTimeRegUpdatePermission && (
							<TimesheetsIconWrapper className="row-hover">
								<TimesheetsIcon onClick={e => timesheetsRedirect(e, rowData.person)} />
							</TimesheetsIconWrapper>
						)}
					</TooltipContainer>
				</div>
			</Table.Column>,
			<Table.Column></Table.Column>,
			<Table.Column></Table.Column>,
			<Table.Column></Table.Column>,
			weekRowsColumns(rowData.person),
			<Table.Column></Table.Column>,
			totalsColumn(rowData.person.total, rowData.person.billableTotal),
			<Table.Column>
				<Table.Column.Cell onClick={() => false}>
					<div style={{display: 'flex', justifyContent: 'space-evenly', gap: '12px'}}>
						{buttonRejection.action && (
							<ForecastTooltip
								content={
									topLevelApprovalDisabled
										? getTopLevelApprovalDisabledMessage('person', 'projects', 'reject')
										: noApprovalStatusChange
										? getNoApprovalStatusChangeMessage(noApprovalStatusChange, intl.formatMessage)
										: intl.formatMessage({id: 'approval.reject'})
								}
							>
								<ActionButton
									isSquare
									variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
									iconPosition={Button.ICON_POSITION.FIRST}
									icon={() => (
										<CrossIcon
											color={actionsDisabled ? '#A1A1A1' : '#F40000'}
											size={CrossIcon.SIZE.SMALL}
										/>
									)}
									disabled={actionsDisabled}
									isLoading={isLoading}
									onClick={e => {
										// Person rows can have both projects and idle times as parents
										const idleTimeParent =
											rowData.person.parentGroupType === GROUP_TYPE.INTERNAL_TIME ||
											rowData.person.parentGroupType === GROUP_TYPE.TIME_OFF;
										onClickHandler(
											e,
											rowData.person.id,
											idleTimeParent ? null : rowData.person.parentId,
											idleTimeParent ? rowData.person.parentId : null,
											false,
											actionsDisabled,
											rowData.person
										);
									}}
									cy={'person-reject-button'}
								></ActionButton>
							</ForecastTooltip>
						)}
						{buttonValidation.action && (
							<ForecastTooltip
								content={
									topLevelApprovalDisabled
										? getTopLevelApprovalDisabledMessage('person', 'projects', 'approve')
										: noApprovalStatusChange
										? getNoApprovalStatusChangeMessage(noApprovalStatusChange, intl.formatMessage)
										: intl.formatMessage({id: 'approval.approve'})
								}
							>
								<ActionButton
									isSquare
									variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
									iconPosition={Button.ICON_POSITION.FIRST}
									icon={() => <CheckmarkIcon />}
									disabled={actionsDisabled}
									isLoading={isLoading}
									onClick={e => {
										// Person rows can have both projects and idle times as parents
										const idleTimeParent =
											rowData.person.parentGroupType === GROUP_TYPE.INTERNAL_TIME ||
											rowData.person.parentGroupType === GROUP_TYPE.TIME_OFF;
										onClickHandler(
											e,
											rowData.person.id,
											idleTimeParent ? null : rowData.person.parentId,
											idleTimeParent ? rowData.person.parentId : null,
											true,
											actionsDisabled,
											rowData.person
										);
									}}
									cy={'person-approve-button'}
								></ActionButton>
							</ForecastTooltip>
						)}
						{buttonReminder.action && (
							<Button
								isSquare
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								iconPosition={Button.ICON_POSITION.FIRST}
								icon={() => <EmailIcon />}
								onClick={() => handleRemind(rowData.person)}
							></Button>
						)}
					</div>
				</Table.Column.Cell>
			</Table.Column>,
		];
	};

	const extractRoleNames = timeRegsOnDayIndex => {
		return timeRegsOnDayIndex.reduce((acc, timeRegsOnDay) => {
			timeRegsOnDay.forEach(timeReg => {
				acc.add(timeReg.role?.name || 'No role');
			});
			return acc;
		}, new Set());
	};

	const roleData = rowData => {
		// Get the role names
		const roleNames = Array.from(extractRoleNames(rowData.task.timeRegsOnDayIndex)) || [];
		// Extract relevant role data from time registrations (timeRegsOnDayIndex)
		return roleNames.map(roleName => {
			const roleTotals = rowData.task.timeRegsOnDayIndex.map(timeRegsOnDay => {
				return (
					timeRegsOnDay.reduce(
						(acc, timeReg) => {
							if (timeReg.role?.name === roleName) {
								acc.totals += timeReg.minutesRegistered;
								acc.billableTotals += timeReg.billableMinutesRegistered;
							} else if (roleName === 'No role' && !timeReg.role) {
								acc.totals += timeReg.minutesRegistered;
								acc.billableTotals += timeReg.billableMinutesRegistered;
							}
							return acc;
						},
						{totals: 0, billableTotals: 0}
					) || null
				);
			});

			const {total, billableTotal} = roleTotals.reduce(
				(acc, roleTotal) => {
					acc.total += roleTotal.totals;
					acc.billableTotal += roleTotal.billableTotals;
					return acc;
				},
				{total: 0, billableTotal: 0}
			);

			const totals = roleTotals.map(roleTotal => {
				return roleTotal.totals;
			});

			const billableTotals = roleTotals.map(roleTotal => {
				return roleTotal.billableTotals;
			});

			return {name: roleName, totals, total, billableTotals, billableTotal};
		});
	};

	const TaskTimeRow = ({rowData, tableColumnsProps}) => {
		// Extract roles from time registrations
		const roles = extractRoleNames(rowData.task.timeRegsOnDayIndex);

		// Only display time registration roles if they are different from the default role
		const isTimeOnNotDefaultRole = rowData.task.timeRegsOnDayIndex.some(timeRegsOnDay =>
			timeRegsOnDay.some(timeReg => timeReg.role?.id !== timeReg.person?.role?.id)
		);

		return (
			<Table.Row
				{...tableColumnsProps}
				cy={'task-row'}
				canExpandRow={isTimeOnNotDefaultRole}
				fixedHeight={isBillableSplitAllowed() ? 48 : null}
			>
				<Table.Column>
					<Table.Column.Clickable onClickHandler={() => showTaskModal(rowData.task.taskId)}>
						{rowData.task.name}
						{isTimeOnNotDefaultRole && <TimeRegistrationRolesDisplay roles={Array.from(roles)} />}
					</Table.Column.Clickable>
				</Table.Column>
				<Table.Column />
				<Table.Column>{Util.convertMinutesToFullHour(rowData.task.estimated, intl, true)}</Table.Column>
				<Table.Column>{Util.convertMinutesToFullHour(rowData.task.totalTaskTimeRegs, intl, true)}</Table.Column>
				{weekRowsColumns(rowData.task)}
				<Table.Column>
					{rowData.task.timeRegistrationsWithNotes.length > 0 && (
						<NotesPopOut
							timeRegItem={{timeRegistrationsWithNotes: rowData.task.timeRegistrationsWithNotes}}
							taskName={rowData.task.name}
							readOnly
						/>
					)}
				</Table.Column>
				{totalsColumn(rowData.task.total, rowData.task.billableTotal)}
				<Table.Column />
			</Table.Row>
		);
	};

	const ProjectTimeRow = ({rowData, tableColumnsProps}) => {
		// Extract roles from time registrations
		const roles = extractRoleNames(rowData.task.timeRegsOnDayIndex);

		// Only display time registration roles if they are different from the default role
		const isTimeOnNotDefaultRole = rowData.task.timeRegsOnDayIndex.some(timeRegsOnDay =>
			timeRegsOnDay.some(timeReg => timeReg.role?.id !== timeReg.person?.role?.id)
		);

		return (
			<Table.Row
				{...tableColumnsProps}
				cy={'task-row'}
				canExpandRow={isTimeOnNotDefaultRole}
				fixedHeight={isBillableSplitAllowed() ? 48 : null}
			>
				<Table.Column>
					<Table.Column.Italic>
						No task
						{isTimeOnNotDefaultRole && <TimeRegistrationRolesDisplay roles={Array.from(roles)} />}
					</Table.Column.Italic>
				</Table.Column>
				<Table.Column />
				<Table.Column />
				<Table.Column />
				{weekRowsColumns(rowData.task)}
				<Table.Column>
					{rowData.task.timeRegistrationsWithNotes.length > 0 && (
						<NotesPopOut
							timeRegItem={{timeRegistrationsWithNotes: rowData.task.timeRegistrationsWithNotes}}
							taskName={rowData.task.name}
							readOnly
						></NotesPopOut>
					)}
				</Table.Column>
				{totalsColumn(rowData.task.total, rowData.task.billableTotal)}
				<Table.Column />
			</Table.Row>
		);
	};

	const RoleTimeRow = ({rowData, tableColumnsProps}) => {
		return (
			<Table.Row {...tableColumnsProps} cy={'role-row'} fixedHeight={isBillableSplitAllowed() ? 48 : null}>
				<Table.Column>{rowData.name}</Table.Column>
				<Table.Column />
				<Table.Column />
				<Table.Column />
				{weekRowsColumns(rowData)}
				<Table.Column />
				{totalsColumn(rowData.total, rowData.billableTotal)}
				<Table.Column />
			</Table.Row>
		);
	};

	const getCanExpandRow = rowData => {
		if (rowData.person && rowData.person.parentGroupType === GROUP_TYPE.INTERNAL_TIME) {
			return false;
		}

		if (rowData.idleTime && rowData.idleTime.parentGroupType === GROUP_TYPE.PERSON) {
			return false;
		}

		return true;
	};

	const getGroupRow = (rowData, tableColumnsProps, topLevelApprovalDisabled) => {
		let content;
		let cy;
		const canExpandRow = getCanExpandRow(rowData);

		if (rowData.person?.id) {
			content = personRow(rowData, topLevelApprovalDisabled);
			cy = 'person-row';
		} else if (rowData.project?.id) {
			content = projectRow(rowData, topLevelApprovalDisabled);
			cy = 'project-row';
		} else if (rowData.idleTime?.id) {
			content = idleTimeRow(rowData);
			cy = 'idle-time-row';
		}

		return (
			<Table.Row
				canExpandRow={canExpandRow}
				hoverStyle={{display: 'flex'}}
				fixedHeight={isBillableSplitAllowed() ? 48 : null}
				{...tableColumnsProps}
				cy={cy}
			>
				{content}
			</Table.Row>
		);
	};

	return (
		<TableWrapper>
			<Table>
				<Table.Header>
					<Table.HeaderColumn visible flex={1} width="LARGE" />
					<Table.HeaderColumn align="left" visible={enabledColumns.projectOwner > 0} width="MEDIUM">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.project_owner'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn align="center" visible={enabledColumns.workEstimate > 0} width="SMALL">
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.work_estimate'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn align="center" visible={enabledColumns.totalTimeReg > 0} width="SMALL">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'common.total_time_regs'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					{weekHeaderSection()}
					<Table.HeaderColumn align="center" visible={true} width="EXTRA_SMALL" bordered>
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.notes'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn align="right" visible={true} width="SMALL" bordered background={'GREY'}>
						<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.totals'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						align="space-evenly"
						visible={true}
						width={sectionType !== APPROVAL_STATUS.SUBMITTED ? 'EXTRA_SMALL' : 'SMALL'}
					></Table.HeaderColumn>
				</Table.Header>
				<Table.Rows data={data} canExpand={true}>
					{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
						return (
							<>
								{getGroupRow(rowData, tableColumnsProps, topLevelApprovalDisabled)}
								{expanded && (
									<Table.Rows data={{rows: getSecondGroupData(rowData)}} canExpand={true} {...nextLevelProps}>
										{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
											return (
												<>
													{getGroupRow(rowData, tableColumnsProps)}
													{expanded && getCanExpandRow(rowData) && (
														<Table.Rows
															data={{rows: timeData(rowData)}}
															canExpand={true}
															{...nextLevelProps}
														>
															{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
																return (
																	<>
																		{rowData.task.taskId ? (
																			<TaskTimeRow
																				rowData={rowData}
																				tableColumnsProps={tableColumnsProps}
																			/>
																		) : (
																			<ProjectTimeRow
																				rowData={rowData}
																				tableColumnsProps={tableColumnsProps}
																			/>
																		)}
																		{expanded && (
																			<Table.Rows
																				data={{rows: roleData(rowData)}}
																				canExpand={false}
																				{...nextLevelProps}
																			>
																				{({rowData, tableColumnsProps}) => (
																					<RoleTimeRow
																						rowData={rowData}
																						tableColumnsProps={tableColumnsProps}
																					/>
																				)}
																			</Table.Rows>
																		)}
																	</>
																);
															}}
														</Table.Rows>
													)}
												</>
											);
										}}
									</Table.Rows>
								)}
							</>
						);
					}}
				</Table.Rows>
			</Table>
		</TableWrapper>
	);
};
export default TimeApprovalTable;
