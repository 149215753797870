import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {clearLocaleCache} from '../../components/canvas-scheduling/canvas-timeline/canvas_timeline_util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {usesSso} from '../../forecast-app/shared/util/FeatureUtil';
import Util from '../../forecast-app/shared/util/util';
import UpdatePersonMutation from '../../mutations/update_person_mutation.modern';
import UpdateViewerMutation from '../../mutations/update_viewer_mutation.modern';
import * as tracking from '../../tracking';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import {SettingsProfile} from './settings_profile';

class SettingsProfileViewer extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isUploading: false,
			showEmailValidationError: false,
			emailValidationErrorMessage: '',
		};

		this.isContactPerson = !!this.props.viewer.company.contactPersons.edges.filter(
			e => e.node.person.id === this.props.viewer.id
		);
		this.superPropertyChecksum = trackPage('Settings Profile');
	}

	componentDidMount() {
		// Segment
		tracking.trackPage('settings-profile');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	settingsRef = React.createRef();

	isEmptyString = obj => typeof obj !== 'string' || obj.trim().length === 0;

	onUpdateEmail(result) {
		if (result.updateViewer.errors && result.updateViewer.errors.length > 0) {
			this.setState({
				showEmailValidationError: true,
				emailValidationErrorMessage: 'Email already exists',
			});
		} else {
			this.showChangesSavedToast();
			this.setState({
				showEmailValidationError: false,
				emailValidationErrorMessage: '',
			});
		}
	}

	showChangesSavedToast() {
		createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
	}

	changeName(firstName, lastName) {
		if (this.isContactPerson && this.isEmptyString(firstName) && this.isEmptyString(lastName)) {
			this.settingsRef.current.handleChangeName(this.props.viewer.firstName + ' ' + this.props.viewer.lastName);
		} else if (firstName !== this.props.viewer.firstName || lastName !== this.props.viewer.lastName) {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					id: this.props.viewer.id,
					firstName,
					lastName,
				},
				() => this.showChangesSavedToast()
			);
		}
	}

	changePhone(phone) {
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				id: this.props.viewer.id,
				phone,
			},
			() => this.showChangesSavedToast()
		);
	}

	changeEmail(email) {
		if (this.isContactPerson && this.isEmptyString(email)) {
			this.settingsRef.current.handleChangeEmail(this.props.viewer.email);
		} else if (email !== this.props.viewer.email && !usesSso(this.props.viewer.company)) {
			showModal({
				type: MODAL_TYPE.CONFIRM_PASSWORD_MODAL,
				mutation: UpdateViewerMutation,
				mutationName: 'updateViewer',
				data: {
					id: this.props.viewer.id,
					email,
				},
				onSuccess: this.onUpdateEmail.bind(this),
				defaultCallback: () => {
					if (this.settingsRef && this.settingsRef.current) {
						this.settingsRef.current.handleChangeEmail(this.props.viewer.email);
					}
				},
			});
		}
	}

	changeJobTitle(jobTitle) {
		if (this.isContactPerson && this.isEmptyString(jobTitle)) {
			this.settingsRef.current.handleChangeJobTitle(this.props.viewer.jobTitle);
		} else if (jobTitle !== this.props.viewer.jobTitle) {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					id: this.props.viewer.id,
					jobTitle,
				},
				() => this.showChangesSavedToast()
			);
		}
	}

	changeRole(roleId) {
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				id: this.props.viewer.id,
				roleId,
			},
			() => this.showChangesSavedToast()
		);
	}

	changeProfile(profile) {
		const onSuccess = res => {
			this.showChangesSavedToast();
			if (
				res.updatePerson?.errors?.length > 0 &&
				(res.updatePerson.errors.includes('NO_DESIGNER_SEATS_LEFT') ||
					res.updatePerson.errors.includes('NO_VIEWER_SEATS_LEFT'))
			) {
				showModal({
					type: MODAL_TYPE.WARNING,
					message: 'Not enough Advanced Analytics seats',
					warningInformation: ['Please contact your account manager if you need to increase your seat limit.'],
				});
			}
		};

		const data = {
			id: this.props.viewer.actualPersonId,
		};

		data.profileIds = profile.map(p => p.value);
		Util.CommitMutation(UpdatePersonMutation, data, onSuccess);
	}

	changeDepartment(departmentId) {
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				id: this.props.viewer.id,
				departmentId,
			},
			() => this.showChangesSavedToast()
		);
	}

	changeLanguage(language) {
		const onSuccess = () => {
			this.showChangesSavedToast();
			this.props.setLocale(language);
			clearLocaleCache();
		};
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				id: this.props.viewer.id,
				language,
			},
			onSuccess
		);
	}

	changeHolidayCalendar(holidayCalendarId) {
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				id: this.props.viewer.id,
				holidayCalendarId,
			},
			() => this.showChangesSavedToast()
		);
	}

	changeProfilePicture(file) {
		this.setState({isUploading: true});
		const onSuccess = result => {
			//Id of picture was not changed, so the picture was not changed, this happens when you try to upload a gif that is too large
			if (result.updateViewer.errors && result.updateViewer.errors.includes('GIF_TOO_LARGE')) {
				createToast({duration: 5000, message: <FormattedMessage id="common.gif_too_large" />});
			} else {
				this.showChangesSavedToast();
			}
			this.setState({isUploading: false});
		};

		Util.CommitMutation(UpdateViewerMutation, {}, onSuccess, true, {
			file: file,
		});
	}

	deleteProfilePicture() {
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				profilePictureId: 'delete',
			},
			() => this.showChangesSavedToast()
		);
	}

	hasSlackOAuthParams() {
		if (this.props.queryParams && this.props.queryParams.slackOauth) {
			try {
				const slackOauth = this.props.queryParams.slackOauth[0];
				if (slackOauth === 'true') {
					return true;
				}
			} catch {
				return false;
			}
		}
		return false;
	}

	render() {
		return (
			<SettingsProfile
				ref={this.settingsRef}
				children={this.props.children}
				person={this.props.viewer}
				viewer={this.props.viewer}
				language={this.props.viewer.language}
				isViewer={true}
				company={this.props.viewer.company}
				showEmailValidationError={this.state.showEmailValidationError}
				emailValidationErrorMessage={this.state.emailValidationErrorMessage}
				viewUser={false}
				changeName={this.changeName.bind(this)}
				changeEmail={this.changeEmail.bind(this)}
				changeJobTitle={this.changeJobTitle.bind(this)}
				changeRole={this.changeRole.bind(this)}
				changeProfile={this.changeProfile.bind(this)}
				changeDepartment={this.changeDepartment.bind(this)}
				changePhone={this.changePhone.bind(this)}
				changeLanguage={this.changeLanguage.bind(this)}
				changeHolidayCalendar={this.changeHolidayCalendar.bind(this)}
				changeProfilePicture={this.changeProfilePicture.bind(this)}
				deleteProfilePicture={this.deleteProfilePicture.bind(this)}
				isUploading={this.state.isUploading}
				currency={this.props.viewer.company.currency}
				availableFeatureFlags={this.props.viewer.availableFeatureFlags}
				oauthSlack={this.hasSlackOAuthParams()}
			/>
		);
	}
}

const settingsProfileViewerQuery = graphql`
	query settingsProfileViewer_Query {
		viewer {
			actualPersonId
			component(name: "settings_profile")
			...settingsProfileViewer_viewer
		}
	}
`;

export {settingsProfileViewerQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(SettingsProfileViewer, {
			viewer: graphql`
				fragment settingsProfileViewer_viewer on Viewer {
					availableFeatureFlags {
						key
					}
					id
					language
					profiles(first: 10000) {
						edges {
							node {
								id
								name
								permissions
							}
						}
					}
					permissions
					initials
					personLabels(first: 10000) {
						edges {
							node {
								label {
									id
									name
									color
								}
							}
						}
					}
					skillPersons {
						skill {
							id
						}
						level {
							id
						}
					}
					actualPersonId
					firstName
					lastName
					email
					jobTitle
					department {
						id
						name
					}
					costCenter
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
					startDate
					endDate
					calendarKey
					profilePictureId
					profilePictureDefaultId
					cost
					role {
						id
						name
					}
					client {
						id
					}
					holidayCalendar {
						id
					}
					language
					company {
						id
						name
						currency
						gitlabEnabled
						gitlabServerEnabled
						githubEnabled
						harvestEnabled
						unit4Enabled
						calendarApiEnabled
						outlookCalendarApiEnabled
						jiraCloudEnabled
						jiraServerEnabled
						bambooHREnabled
						newSlackEnabled
						sageIntacctConnectionEnabled
						oktaEnabled
						oneloginEnabled
						AADEnabled
						vstsEnabled
						tier
						weekendDisplayPerUser
						weekendDisplayShowAlways
						modules {
							moduleType
						}
						contactPersons {
							edges {
								node {
									id
									contactType
									person {
										id
										fullName
										email
										phone
									}
								}
							}
						}
						profiles(first: 100000) {
							edges {
								node {
									id
									name
									canBeEdited
									personsCount
									permissions
									disabled
								}
							}
						}
						departments(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						roles(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						...ProfileSkills_company
						labels(first: 10000, labelType: PERSON) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
									...LabelDropdown_labels
								}
							}
						}
						holidayCalendars(first: 10000) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
					gitlabUser {
						integrationUserId
					}
					gitlabServerUser {
						integrationUserId
					}
					githubUser {
						integrationUserId
					}
					unit4UserObject
					bambooHRId
					harvestUser
					jiraCloudId
					jiraServerId
					sisenseUserId
					slackId
					sageIntacctId
					sageIntacctName
					sageIntacctEmail
					sageIntacctLocationId
					adoUserId
					...SettingsProfileSubNav_viewer
					...SettingsCalendarWrapper_viewer
				}
			`,
		})
	)
);
