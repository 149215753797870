import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Warning from '../../../components/warning';
import { BUTTON_COLOR, BUTTON_STYLE } from '../../../constants';
import DirectApi from '../../../directApi';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import { MODAL_TYPE, showModal } from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import { hasPermission } from '../../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../../forecast-app/shared/util/util';
import UpdatePersonMutation from '../../../mutations/update_person_mutation.modern';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import { PERMISSION_TYPE } from '../../../Permissions';
import { UnlinkButton } from '../UnlinkButton';
function useHibobUsers({ skip }) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const fetchHiBobUsers = () => {
        if (!skip) {
            setLoading(true);
            DirectApi.Fetch('hibob/employees?onlyFetchUnlinkedEmployees=true').then(res => {
                if (res === null || res === void 0 ? void 0 : res.employees) {
                    setUsers(res.employees);
                    setLoading(false);
                }
                else {
                    setUsers([]);
                    setLoading(false);
                }
            });
        }
    };
    useEffect(() => {
        fetchHiBobUsers();
    }, []);
    return { users, loading, retry: fetchHiBobUsers };
}
const SelectUser = ({ onSelectUser, connectionEnabled }) => {
    const { users: hiBobUsers, loading: hiBobLoading, retry } = useHibobUsers({ skip: !connectionEnabled });
    const dropdownOptions = useMemo(() => {
        return hiBobUsers
            .map((t) => ({
            value: t.id,
            label: t.fullName,
        }))
            .sort((a, b) => (a.label.toUpperCase().trim() > b.label.toUpperCase().trim() ? 1 : -1));
    }, [hiBobUsers]);
    return (React.createElement(React.Fragment, null,
        `Select a profile from the list below to associate this team member with a HiBob profile.`,
        React.createElement("div", { className: "common-integration-user-dropdown" },
            React.createElement(Dropdown, { value: null, options: dropdownOptions, onChange: (option) => onSelectUser((option === null || option === void 0 ? void 0 : option.value) || null), hideLabel: true, placeholder: `Select HiBob profile`, customHeight: 30, integrationDropdown: true, integrationLoading: hiBobLoading, integrationRetryToFetch: retry }))));
};
function useHibobUser({ id, skip }) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!skip) {
            setLoading(true);
            DirectApi.Fetch(`hibob/employees/${id}`).then(res => {
                if (res === null || res === void 0 ? void 0 : res.employee) {
                    setUser(res.employee);
                    setLoading(false);
                }
                else {
                    setUser(undefined);
                    setLoading(false);
                }
            });
        }
    }, [id, skip]);
    return { user, loading };
}
const LinkedUser = ({ id, name, onUnlink, connectionEnabled }) => {
    const { user: hiBobUser } = useHibobUser({ id, skip: !connectionEnabled });
    return (React.createElement(React.Fragment, null,
        hiBobUser
            ? `${name} is linked to the following HiBob profile: ${hiBobUser.fullName}`
            : `${name} is linked with a HiBob profile.`,
        React.createElement("div", null,
            React.createElement(UnlinkButton, { onClick: onUnlink }))));
};
export const HiBobIntegration = ({ person, viewUser, company }) => {
    const { formatMessage } = useIntl();
    const setHiBobUser = (hiBobId) => {
        const [mutation, data] = viewUser
            ? [UpdatePersonMutation, { id: person.id, hiBobId }]
            : [UpdateViewerMutation, { hiBobId }];
        if (hiBobId) {
            Util.CommitMutation(mutation, data, null, true);
        }
        else {
            showModal({
                type: MODAL_TYPE.GENERIC,
                className: 'default-warning-modal',
                content: React.createElement(Warning, { message: "Are you sure you want to unlink this user from HiBob?" }),
                buttons: [
                    {
                        text: formatMessage({ id: 'common.cancel' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.WHITE,
                    },
                    {
                        text: formatMessage({ id: 'common.unlink' }),
                        style: BUTTON_STYLE.FILLED,
                        color: BUTTON_COLOR.RED,
                        callback: () => Util.CommitMutation(mutation, data, null, true),
                    },
                ],
            });
        }
    };
    const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
    if (!company.hiBobEnabled || person.client || (viewUser && !isAdmin)) {
        return null;
    }
    return (React.createElement("fieldset", { className: "common-integration-settings" },
        React.createElement("h3", null, "HiBob"),
        React.createElement("p", { className: "description" }, person.hiBobId ? (React.createElement(LinkedUser, { id: person.hiBobId, name: person.firstName, onUnlink: () => setHiBobUser(null), connectionEnabled: company.hiBobConnectionEnabled })) : (React.createElement(SelectUser, { onSelectUser: setHiBobUser, connectionEnabled: company.hiBobConnectionEnabled })))));
};
