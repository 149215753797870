import React, {useState, useEffect} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl, FormattedMessage} from 'react-intl';
import GenericModal from '../generic_modal';
import InvoiceEntriesList from '../create_invoice_modal_v2/steps/invoice_entries_list';
import {
	InvoiceDropdownContainer,
	InvoiceDataLine,
	InvoiceDataLineTwo,
	ContentWrapper,
	SwitchWrapper,
	SwitchTitle,
} from '../../../styles/v2/invoice_modals_v2_styled';
import CreateQuickbooksInvoiceMutation from '../../../mutations/CreateQuickbooksInvoiceMutation';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Switch from '../../../forecast-app/shared/components/switch/switch';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {showModal, MODAL_TYPE} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../forecast-app/shared/util/util';
import {hideLoader, showLoader} from '../../global_loader';
import {setLocalStorageSilent, getLocalStorageSilent} from '../../../forecast-app/shared/util/LocalStorageUtil';
import DirectApi from '../../../directApi';
import {
	UneditableMessageBox,
	MessageContainer,
	PadLockContainer,
} from '../../../styles/sections/insights/invoicing/invoicing_page_styled';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const quickbooksExportModal = ({closeModal, viewer, quickbooksCustomers}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {invoice, projects} = viewer;
	const {entries} = invoice;
	const mappedProjects = projects.edges.map(p => ({...p.node}));
	const clientProjects = mappedProjects.filter(project =>
		project.client && invoice.client ? project.client.id === invoice.client.id : false
	);
	const initialInvoiceLines = entries.edges.map(e => ({
		...e.node,
		projectId: e.node.project ? e.node.project.id : null,
		originalDescription: e.node.description,
		quantity: e.node.quantityText,
	}));

	const [invoiceLines, setInvoiceLines] = useState(initialInvoiceLines);
	const [quickbooksItemsAndServices, setQuickbooksItemsAndServices] = useState([{value: '-1', label: 'Loading...'}]);
	const [quickbooksTaxCodes, setQuickbooksTaxCodes] = useState([{value: '-1', label: 'Loading...'}]);
	const [selectedClient, setSelectedClient] = useState(
		invoice.client && invoice.client.quickbooksCustomerId ? invoice.client.id : null
	);
	const [realmId, setRealmId] = useState(null);
	const [selectedQuickbooksTaxCode, setSelectedQuickbooksTaxCode] = useState('-1');
	const [selectedQuickbooksItemOrService, setSelectedQuickbooksItemOrService] = useState('-1');
	const [exporting, setExporting] = useState(false);

	let qboInvoiceRefVal = getLocalStorageSilent('forecast_qboInvoiceRef') === 'true',
		qboDescriptionVal = getLocalStorageSilent('forecast_qboDescription') === 'true',
		qboTermsVal = getLocalStorageSilent('forecast_qboTerms') === 'true',
		qboDefaultTaxVal = getLocalStorageSilent('forecast_qboDefaultTax') === 'true',
		qboIncludeProjectNameInDescVal = getLocalStorageSilent('forecast_qboIncludeProjectNameInDesc') === 'true';

	const [qboInvoiceRef, setQboInvoiceRef] = useState(qboInvoiceRefVal);
	const [qboDescription, setQboDescription] = useState(qboDescriptionVal);
	const [qboTerms, setQboTerms] = useState(qboTermsVal);
	const [qboDefaultTax, setQboDefaultTax] = useState(qboDefaultTaxVal);
	const [qboIncludeProjectNameInDesc, setIncludeProjectNameInDesc] = useState(qboIncludeProjectNameInDescVal);

	const toggleQBOInvoiceRef = () => {
		const val = !qboInvoiceRef;
		setLocalStorageSilent('forecast_qboInvoiceRef', val);
		setQboInvoiceRef(val);
	};

	const toggleQBOIncludeProjectNameInDesc = () => {
		const val = !qboIncludeProjectNameInDesc;
		setLocalStorageSilent('forecast_qboIncludeProjectNameInDesc', val);
		setIncludeProjectNameInDesc(val);

		// Toggle qboDescription off if qboIncludeProjectNameInDesc is on as they both can not be on at the same time
		if (qboDescription && !qboIncludeProjectNameInDesc) {
			const val = !qboDescription;
			setLocalStorageSilent('forecast_qboDescription', val);
			setQboDescription(val);
		}
	};

	const toggleQBODescription = () => {
		const val = !qboDescription;
		setLocalStorageSilent('forecast_qboDescription', val);
		setQboDescription(val);

		// Toggle qboIncludeProjectNameInDesc off if qboDescription is on as they both can not be on at the same time
		if (!qboDescription && qboIncludeProjectNameInDesc) {
			toggleQBOIncludeProjectNameInDesc();
		}
	};

	const toggleQBOTerms = () => {
		const val = !qboTerms;
		setLocalStorageSilent('forecast_qboTerms', val);
		setQboTerms(val);
	};

	const selectQuickbooksTaxCode = e => {
		setSelectedQuickbooksTaxCode(e.value);
		invoiceLines.forEach(line => {
			line.quickbooksTaxRate = e.taxRate;
		});
	};

	const toggleQBODefaultTax = () => {
		const val = !qboDefaultTax;
		setLocalStorageSilent('forecast_qboDefaultTax', val);
		setQboDefaultTax(val);

		selectQuickbooksTaxCode({value: '-1', taxRate: 0.0});
	};

	const selectQuickbooksItem = e => {
		setSelectedQuickbooksItemOrService(e.value);
		invoiceLines.forEach(line => {
			line.quickbooksItem = e.value;
			line.quickbooksDescription = e.description;
			line.quickbooksTaxable = e.taxable;
			line.quickbooksUnitPrice = e.unitPrice;
		});
	};

	const handleSelectedClient = option => {
		setSelectedClient(option.value);
		setRealmId(option.realmId);
	};

	const content = (
		<ContentWrapper minHeight="600px">
			<UneditableMessageBox style={{marginBottom: '30px'}}>
				<PadLockContainer>
					<LockIcon size={LockIcon.SIZE.LARGE} color={CSS_CONSTANTS.v2_text_gray} />
				</PadLockContainer>
				<MessageContainer>{formatMessage({id: 'invoicing.cannot_edit_warning_exporting'})}</MessageContainer>
			</UneditableMessageBox>
			<InvoiceDataLineTwo>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => handleSelectedClient(option)}
						required={true}
						options={quickbooksCustomers}
						value={selectedClient}
						label={formatMessage({id: 'common.customer'})}
						placeholder={formatMessage({id: 'common.customer'})}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectQuickbooksTaxCode(option)}
						options={quickbooksTaxCodes}
						value={selectedQuickbooksTaxCode}
						label={formatMessage({id: 'integrations.quickbooks.tax_code'})}
						placeholder={formatMessage({id: 'integrations.quickbooks.tax_code'})}
						disabled={qboDefaultTax}
					/>
				</InvoiceDropdownContainer>
				<InvoiceDropdownContainer>
					<Dropdown
						onChange={option => selectQuickbooksItem(option)}
						options={quickbooksItemsAndServices}
						value={selectedQuickbooksItemOrService}
						label={formatMessage({id: 'integrations.quickbooks.items_and_services'})}
						placeholder={formatMessage({id: 'integrations.quickbooks.items_and_services'})}
					/>
				</InvoiceDropdownContainer>
			</InvoiceDataLineTwo>
			<InvoiceDataLine>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.quickbooks.control_reference" />
					</SwitchTitle>
					<Switch onChange={() => toggleQBOInvoiceRef()} checked={qboInvoiceRef} sliderWidth={30} sliderHeight={18} />
				</SwitchWrapper>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.quickbooks.use_product_service_descriptions" />
					</SwitchTitle>
					<Switch
						onChange={() => toggleQBODescription()}
						checked={qboDescription}
						sliderWidth={30}
						sliderHeight={18}
					/>
				</SwitchWrapper>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.quickbooks.include_project_name_in_description_when_exporting" />
					</SwitchTitle>
					<Switch
						onChange={() => toggleQBOIncludeProjectNameInDesc()}
						checked={qboIncludeProjectNameInDesc}
						sliderWidth={30}
						sliderHeight={18}
					/>
				</SwitchWrapper>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.quickbooks.use_terms" />
					</SwitchTitle>
					<Switch onChange={() => toggleQBOTerms()} checked={qboTerms} sliderWidth={30} sliderHeight={18} />
				</SwitchWrapper>
				<SwitchWrapper>
					<SwitchTitle>
						<FormattedMessage id="invoicing.quickbooks.use_default_tax" />
					</SwitchTitle>
					<Switch onChange={() => toggleQBODefaultTax()} checked={qboDefaultTax} sliderWidth={30} sliderHeight={18} />
				</SwitchWrapper>
			</InvoiceDataLine>
			<InvoiceEntriesList
				invoiceExporting={true}
				lines={invoiceLines.map(line => {
					const convertedLine = {...line};
					convertedLine.description = (qboDescription && line.quickbooksDescription) || line.originalDescription;
					convertedLine.tax = line.quickbooksTaxable ? line.quickbooksTaxRate : 0;
					return convertedLine;
				})}
				projects={mappedProjects}
				clientProjects={clientProjects}
				currency={invoice.currency}
				setInvoiceLines={setInvoiceLines}
				invoice={invoice}
				integrationData={{
					quickbooks: {
						enabled: true,
						itemsAndServices: quickbooksItemsAndServices,
					},
				}}
			/>
		</ContentWrapper>
	);

	const onInvoiceExport = () => {
		setExporting(true);
		showLoader();
		// Export invoice to Quickbooks
		Util.CommitMutation(
			CreateQuickbooksInvoiceMutation,
			{
				invoiceId: invoice.id,
				forecastClientId: selectedClient,
				entryMap: invoiceLines.map(line => ({entryId: line.id, itemId: line.quickbooksItem})),
				letQBOSetDescription: qboDescription,
				letQBOSetInvoiceRef: qboInvoiceRef,
				useQBOTerms: qboTerms,
				useQBODefaultTax: qboDefaultTax,
				taxCodeId: selectedQuickbooksTaxCode,
				includeProjectNameInDesc: qboIncludeProjectNameInDesc,
			}, // Toast
			result => {
				setExporting(false);
				closeModal();
				hideLoader();
				if (result?.createQuickbooksInvoice?.success) {
					createToast({duration: 5000, message: intl.formatMessage({id: 'integrations.quickbooks.invoice_created'})});
				} else {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'invoicing.not_exported',
						warningInformation: [
							result?.createQuickbooksInvoice?.errorMessage || 'The invoice could not be exported.',
						],
						buttons: [
							{
								text: formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				}
			}
		);
	};

	const getQuickbooksItemsAndServices = () => {
		try {
			DirectApi.Fetch(`quickbooks/items_and_services${realmId ? `/${realmId}` : ''}`).then(json => {
				setQuickbooksItemsAndServices(
					json.map(item => ({
						value: item.id,
						label: item.name,
						description: item.description,
						unitPrice: item.unitPrice,
						taxable: item.taxable,
					}))
				);
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const getQuickbooksTaxCodes = () => {
		try {
			DirectApi.Fetch(`quickbooks/tax_codes${realmId ? `/${realmId}` : ''}`).then(json => {
				const qbTaxCodes = json.map(taxCode => ({
					value: taxCode.id,
					label: taxCode.name,
					taxRate: taxCode.tax,
				}));

				setQuickbooksTaxCodes(qbTaxCodes);

				selectQuickbooksTaxCode(qbTaxCodes.find(taxCode => taxCode.value === '-1'));
			});
		} catch (error) {
			//TODO: Show error?
		}
	};

	const modalButtons = [
		{
			text: formatMessage({id: 'common.cancel'}),
			callback: closeModal.bind(this),
			color: BUTTON_COLOR.WHITE,
		},
		{
			text: formatMessage({id: 'common.export'}),
			preventDefaultClose: true,
			callback: onInvoiceExport,
			disabled: exporting || selectedClient === null || invoiceLines.find(line => !line.quickbooksItem),
		},
	];

	useEffect(() => {
		if (selectedClient) {
			getQuickbooksItemsAndServices();
		}
	}, [selectedClient]);

	useEffect(() => {
		if (selectedClient) {
			getQuickbooksTaxCodes();
		}
	}, [selectedClient]);

	return (
		<GenericModal
			buttons={modalButtons}
			headerText={formatMessage({id: 'invoicing.export_to'}, {systemName: 'Quickbooks'})}
			closeModal={closeModal}
			content={content}
		/>
	);
};

const quickbooksExportModalQuery = graphql`
	query quickbooksExportModal_Query($invoiceId: ID) {
		viewer {
			actualPersonId
			component(name: "quickbooks_export_modal")
			...quickbooksExportModal_viewer @arguments(invoiceId: $invoiceId)
		}
	}
`;

export {quickbooksExportModalQuery};

export default createFragmentContainer(quickbooksExportModal, {
	viewer: graphql`
		fragment quickbooksExportModal_viewer on Viewer @argumentDefinitions(invoiceId: {type: "ID"}) {
			id
			backendId
			invoice(id: $invoiceId) {
				id
				invoiceReference
				companyInvoiceId
				name
				currency
				invoiceType
				status
				dueDay
				dueMonth
				dueYear
				createdDay
				createdMonth
				createdYear
				notes
				quickbooksId
				xeroId
				economicId
				client {
					id
					quickbooksCustomerId
				}
				entries(first: 100000000) {
					edges {
						node {
							id
							name
							quantity
							quantityText
							unitPrice
							discount
							tax
							description
							quantityLockedReason
							project {
								id
								companyProjectId
								name
								projectColor
								isInProjectGroup
								budgetType
								client {
									name
								}
								rateCard {
									currency
								}
							}
						}
					}
				}
			}
			projects {
				edges {
					node {
						id
						name
						projectColor
						isInProjectGroup
						companyProjectId
						projectGroupId
						budgetType
						status
						fullAccessToProject
					}
				}
			}
		}
	`,
});
